<div class="row">
    <label class="switch switch radio-inline">
        <input
                type="checkbox"
                [checked]="isUser"
                (change)="isUser = !isUser;isUser ? this.generateForm() : this.removeForm(0)"
        >
        <span></span>
    </label>
</div>
<div *ngFor="let formUser of ngForms; let i = index;" class="row mt-lg">
    <form [formGroup]="formUser">
        <div class="col-xs-12">
            <div class="row mt-lg">
                <div class="col-xs-12">
                    <input
                            type="text"
                            class="form-control"
                            [formControl]="formUser.controls['employee_id']"
                            [placeholder]="'Employee ID'"
                    />
                    <sub-form-errors
                            [ctrl]="formUser.controls['first_name']"
                            [name]="'first_name'"
                            [validationMessages]="validationMessages['first_name']"
                            [forceShowValidation]="forceShowValidation"
                    ></sub-form-errors>
                </div>
            </div>
            <div class="row mt-lg">
                <div class="col-xs-12">
                    <input
                            type="text"
                            class="form-control"
                            [formControl]="formUser.controls['first_name']"
                            [placeholder]="'First Name'"
                    />
                    <sub-form-errors
                            [ctrl]="formUser.controls['first_name']"
                            [name]="'first_name'"
                            [validationMessages]="validationMessages['first_name']"
                            [forceShowValidation]="forceShowValidation"
                    ></sub-form-errors>
                </div>
            </div>
            <div class="row mt-lg">
                <div class="col-xs-12">
                    <input
                            type="text"
                            class="form-control"
                            [formControl]="formUser.controls['last_name']"
                            [placeholder]="'Last Name'"
                    />
                    <sub-form-errors
                            [ctrl]="formUser.controls['last_name']"
                            [name]="'last_name'"
                            [validationMessages]="validationMessages['last_name']"
                            [forceShowValidation]="forceShowValidation"
                    ></sub-form-errors>
                </div>
            </div>
            <div class="row mt-lg">
                <div class="col-xs-12">
                    <input
                            type="text"
                            class="form-control"
                            [formControl]="formUser.controls['email']"
                            [placeholder]="'Email Address'"
                    />
                    <sub-form-errors
                            [ctrl]="formUser.controls['email']"
                            [name]="'email'"
                            [validationMessages]="validationMessages['email']"
                            [forceShowValidation]="forceShowValidation"
                    ></sub-form-errors>
                </div>
                <div class="col-xs-12 mt-lg">
                    <input
                            type="text"
                            class="form-control"
                            [formControl]="formUser.controls['email_confirm']"
                            [placeholder]="'Confirm Email'"
                    />
                    <sub-form-errors
                            [ctrl]="formUser.controls['email_confirm']"
                            [name]="'email_confirm'"
                            [validationMessages]="validationMessages['email_confirm']"
                            [forceShowValidation]="forceShowValidation"
                    ></sub-form-errors>
                </div>
            </div>
        </div>
    </form>
</div>
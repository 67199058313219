// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    hmr       : false,
    baseUrl: 'http://capturebox.io',
    api: {
        baseUrl: 'http://api.capturebox.io/v1',
        authUrl: 'http://auth.capturebox.io',
        formsUrl: 'http://forms.capturebox.io/v1',
    },
    publicKey: `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAxvYa8I8kt2xtuAGE6S/w
MWqj6yV2lsWnxTcJYGVo6nEGgdswETCgByc6iL87bhXFK5tIkjjAd+GpaNU5D55Y
+Wvg9giafRglY8My9WNuGVmAqXvQLTOYui4PAsNw34bqxnW1L2FVS11sdlTpMW8c
ahVeyu9xMGzkVbngXJc1icUZQOUMhqoe8mz/1n3sizF7QZ29SySYplMHMJr5yxBr
oQ8Ixl5YgiIB5h2oqqlgvHu4436VEE1y3/nl7UEfau1S2fqZ9xlVJ6fvRsn53Z/S
O/NGxExOcCV4IZ8kNkER3bn0EQxaOpiuI4gcAw9YqcrzCFbueZDr1/7VucQuD0hv
YwIDAQAB
-----END PUBLIC KEY-----`,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

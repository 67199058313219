<div id="dashboard-project" class="page-layout simple right-sidebar" fxLayout="row">

    <!-- CENTER -->
    <div class="center" fusePerfectScrollbar>

        <!-- CONTENT -->
        <div class="content">

            <div class="widget-group p-12" fxLayout="row wrap" fxFlex="100" *fuseIfOnDom
                 [@animateStagger]="{value:'50'}">

                <!-- WIDGET 1 -->
                <fuse-widget
                    *ngFor="let recipe of dashboardService.recipes"
                    [@animate]="{value:'*',params:{y:'100%'}}"
                    class="widget"
                    fxLayout="column"
                    fxFlex="100"
                    fxFlex.gt-xs="50"
                    fxFlex.gt-md="25"
                >
                    <!-- Front -->
                    <div class="fuse-widget-front">
                        <div class="pl-16 pr-8 py-16 h-36" fxLayout="row"
                             fxLayoutAlign="space-between center">
                            <div class="h3">{{recipe.name}}</div>

                            <button mat-icon-button fuseWidgetToggle aria-label="more">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                        </div>

                        <div class="pt-8 pl-24 pr-24 pb-32" fxLayout="column" fxLayoutAlign="center center">
                            <div class="h3 secondary-text font-weight-500 text-uppercase">
                                {{ recipe.description }}
                            </div>
                        </div>
                    </div>
                    <!-- / Front -->

                    <!-- Back -->
                    <div class="fuse-widget-back p-16 pt-32">
                        <button mat-icon-button fuseWidgetToggle class="fuse-widget-flip-button"
                                aria-label="Flip widget">
                            <mat-icon class="s-16">close</mat-icon>
                        </button>

                        <div>
                            {{ recipe.instructions }}
                        </div>
                    </div>
                    <!-- / Back -->
                </fuse-widget>
            </div>
        </div>
        <!-- / CONTENT -->
    </div>
    <!-- / CENTER -->
</div>

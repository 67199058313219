<h1 md-dialog-title>{{'New ' + title}}</h1>
<div md-dialog-content>
    <app-form
        [isModal]="true"
        [formName]="formName"
        [forceShowValidation]="forceShowValidation"
        (updated)="onFormUpdate($event)"
    ></app-form>
</div>
<span class="pull-right">
    <button
        class="btn btn-primary pull-right"
        [disabled]="!canSubmit"
        (click)="submit()"
    >
        Submit
    </button>
    <button
        class="btn btn-default pull-right mr-lg"
        (click)="onCancelModal($event)"
    >
        Cancel
    </button>
</span>

import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
declare let $: any;

@Injectable()
export class SettingsService {

    public user: any;
    public app: any;
    public layout: any;
    public analytics: {
        novologic: string,
        reseller: string,
        client: Array<string>,
    };

    constructor() {

        this.analytics = {
            novologic: '12345',
            reseller: null,
            client: [],
        };

        // User Settings
        // -----------------------------------
        // TODO verify this is needed and if it is,
        // does it need to be adjusted in any way
        this.user = {
            name: 'John',
            job: 'ng-developer',
            picture: 'assets/img/user/02.jpg'
        };

        // App Settings
        // -----------------------------------
        this.app = {
            version: 'Connect', // Dashboard
            client: '',
            oem: 'NovoLogic',
            logoSmall: 'assets/img/logo-small.png',
            logoWide: 'assets/img/catalyst-text-logo-wide.png',
            logoWideBlue: 'assets/img/catalyst-logo-wide-blue.png',
            logoNovoWideBlue: 'assets/img/novo-logo-wide-blue.png',
            headertitle: 'CatalystConnect',
            welcometext: 'Welcome to CatalystConnect',
            footertext: 'Powered by Clay Garland',
            logo: false,
            footerurl: 'https://novologic.com',
            teamdashboard: false,
            modules: [],
            leadmanagebuttons: false,
            awsS3BucketBaseUrl: '',
        };

        // Layout Settings
        // -----------------------------------
        this.layout = {
            isFixed: true,
            isCollapsed: true,
            isBoxed: false,
            isRTL: false,
            horizontal: false,
            isFloat: false,
            asideHover: false,
            theme: null,
            asideScrollbar: false,
            isCollapsedText: false,
            useFullLayout: false,
            hiddenFooter: false,
            offsidebarOpen: false,
            inTheMomentOpen: false,
            feedbackSidebarOpen: false,
            helpSidebarOpen: false,
            asideToggled: false,
            viewAnimation: 'ng-fadeInUp'
        };
    }

    getAppSetting(name) {
        return name ? this.app[name] : this.app;
    }
    getUserSetting(name) {
        return name ? this.user[name] : this.user;
    }
    getLayoutSetting(name) {
        return name ? this.layout[name] : this.layout;
    }

    addGoogleAnalyticsKey(trackingId: string, reseller: boolean) {
        if (reseller) {
            this.analytics.reseller = trackingId;
            return;
        }
        this.analytics.client.push(trackingId);
    }

    setAppSetting(name, value) {
        if (typeof this.app[name] !== 'undefined' && value) {
            this.app[name] = value;
        }
    }

    setAppSettings(settings) {
        for (const key in settings) {
            if (typeof(settings[key]) !== 'undefined') {
                if (key === 'reseller' && settings[key] === 'aslan') {
                    this.app.leadmanagebuttons = true;
                    continue;
                }
                this.setAppSetting(key, settings[key]);
            }
        }
    }

    setUserSetting(name, value) {
        if (typeof this.user[name] !== 'undefined') {
            this.user[name] = value;
        }
    }
    setLayoutSetting(name, value) {
        if (typeof this.layout[name] !== 'undefined') {
            return this.layout[name] = value;
        }
    }

    toggleLayoutSetting(name) {
        return this.setLayoutSetting(name, !this.getLayoutSetting(name));
    }

}

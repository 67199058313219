import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id      : 'home',
        title   : 'Home',
        type    : 'item',
        icon    : 'dashboard',
        url     : '/home'
    },
    {
        id      : 'recipes',
        title   : 'Recipes',
        type    : 'item',
        icon    : 'fastfood',
        url     : '/admin/recipes'
    },
    {
        id      : 'ingredients',
        title   : 'Ingredients',
        type    : 'item',
        icon    : 'subject',
        url     : '/admin/ingredients'
    },
    {
        id      : 'users',
        title   : 'Users',
        type    : 'item',
        icon    : 'group',
        url     : '/admin/users'
    },
];

import {Component, OnInit} from '@angular/core';
import {BaseAdminListComponent} from '@core/component/datatable/BaseAdminList';
import {IColumnDefinition} from '@shared/interfaces/IColumnDefinition';
import {IngredientService, IngredientServiceProvider} from '@shared/services/ingredient.service';

@Component({
    selector: 'app-ingredients',
    templateUrl: './ingredients.component.html',
    styleUrls: ['./ingredients.component.scss'],
    providers: [
        IngredientServiceProvider,
    ]
})
export class IngredientsComponent extends BaseAdminListComponent implements OnInit {

    ingredientQuery = '';

    constructor(
        protected service: IngredientService,
    ) {
        super();
    }

    getColumnDefinitions(): Array<IColumnDefinition> {
        return [
            {
                name: 'ID',
                prop: 'id',
                sortable: true,
                filter: true,
            },
            {
                name: 'Name',
                prop: 'name',
                sortable: true,
                filter: true,
            },
            {
                name: 'Display Name',
                prop: 'display_name',
                sortable: true,
                filter: true,
            },
        ];
    }

    protected refreshFunction(showInactive?: boolean): void {
        this.loading = true;
        this.subscribe(this.service.get(
            ((showInactive) ? this.ingredientQuery + '&show_deleted=1' : this.ingredientQuery),
            true
        ), (response) => {
            this.items = response.data;
            this.loading = false;
        });
    }

    ngOnInit(): void {
        this.columns = this.getColumnDefinitions();
        this.filterColumns = this.getFilterColumns();
        this.subscribe(
            this.service.get(
                this.ingredientQuery,
                true
            ), (response) => {
                this.items = response.data;
                this.loading = false;
            }
        );
    }
}

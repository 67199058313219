export class Filters {

    private static stringContainsArray(query: string[], subject: string): boolean {
        const contains = [];
        query.forEach((search, i) => {
            if (search === '') {
                return;
            }
            contains[i] = subject.toLowerCase().indexOf(search) !== -1;
        });
        return contains.filter(val => !val).length === 0;
    }

    public static stringContains(subject: string | string[], query: string | string[]): boolean {
        if (!query || query.length === 0) {
            return true;
        }

        if (typeof (subject) === 'undefined' || subject === null) {
            return false;
        }

        if (typeof(query) === 'string' && query.indexOf(' ') !== -1) {
            query = query.split(' ');
        }

        return (typeof (subject) !== 'object')
            ? ((typeof (query) === 'object')
                ? Filters.stringContainsArray(query, subject)
                : `${subject}`.toLowerCase().indexOf(query.toLowerCase()) !== -1)
            : Filters.callArray(Filters.stringContains, subject, query);
    }

    public static numberGreaterThan(subject: number, value: number): boolean {
        return +subject > +value;
    }

    public static numberLessThan(subject: number, value: number): boolean {
        return +subject < +value;
    }

    public static equal(subject: any, value: any): boolean {
        return subject === value;
    }

    public static callArray(fn, subject, query): boolean {
        for (const item of subject) {
            if (fn(item, query)) {
                return true;
            }
        }
        return false;
    }

}

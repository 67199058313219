import { Injectable } from '@angular/core';
import {BaseApi} from '@core/api/BaseApi';
import {environment} from '../../environments/environment';
import {Observable, ReplaySubject} from 'rxjs';

@Injectable()
export class AclService extends BaseApi {
  protected baseUrl = environment.api.authUrl;
  protected resourcePath = '';

  post(path: string = '', data, onSuccess?, onError?): Observable<any> {
    const subject = new ReplaySubject(1);
    try {
        this.http.post(this.baseUrl + path, data).subscribe(
            (typeof(onSuccess) !== 'undefined') ? onSuccess : res => subject.next(res),
            (typeof(onError) !== 'undefined') ? onError : err => subject.next(err)
        );
    } catch (err) {
      subject.next({});
    }
    return subject.asObservable();
  }
}

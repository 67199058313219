<div [ngSwitch]="element['component']" fxLayout="row">
    <ingredient-multiselect
        *ngSwitchCase="'ingredient-multiselect'"
        [placeholder]="element['label']"
        (updated)="updated.emit($event)"
        [ctrl]="ctrl"
    ></ingredient-multiselect>
    <image-upload-single
        *ngSwitchCase="'image-upload-single'"
        (updated)="updated.emit($event)"
        [selectedValue]="element['value']"
        [ctrl]="ctrl"
    ></image-upload-single>
    <change-password-btn
        *ngSwitchCase="'change-password-btn'"
    ></change-password-btn>
    <file-input
        *ngSwitchCase="'file-input'"
        (updated)="updated.emit($event)"
        [selectedValue]="element['value']"
        [ctrl]="ctrl"
    ></file-input>
    <carousel-item-input
        *ngSwitchCase="'carousel-item'"
        (updated)="updated.emit($event)"
        [selectedValue]="element['value']"
        [ctrl]="ctrl"
    ></carousel-item-input>
</div>

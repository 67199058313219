import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseApi} from '@core/api/BaseApi';


@Injectable()
export class IngredientService extends BaseApi {
  protected resourcePath = '/ingredients';
}

export const IngredientServiceProvider = {
  provide: IngredientService,
  useFactory: ingredientServiceFactory,
  deps: [HttpClient]
};

export function ingredientServiceFactory(http: HttpClient): IngredientService {
  return new IngredientService(http);
}

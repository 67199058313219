import {AclService} from '@shared/services/acl.service';
import {AclUtils} from './AclUtils';
import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
    selector: '[canView]'
})
export class CanViewDirective {

    permissions;

    private acl;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private aclService: AclService,
    ) {
        this.acl = aclService.get('/acl');
    }

    @Input() set canView(requiredPermissions: string) {
        if (typeof(requiredPermissions) === 'string') {
            this.acl.subscribe(({acl}) => {
                const hasPermission = AclUtils.hasPermission(requiredPermissions, acl);
                if (!hasPermission) {
                    return this.viewContainer.clear();
                }
            });
        }
        this.viewContainer.createEmbeddedView(this.templateRef);
    }

}

import {Component, OnInit} from '@angular/core';
import {BaseAdminListComponent} from '@core/component/datatable/BaseAdminList';
import {IColumnDefinition} from '@shared/interfaces/IColumnDefinition';
import {ManufacturerService, ManufacturerServiceProvider} from '@shared/services/manufacturer.service';

@Component({
    selector: 'app-recipes',
    templateUrl: './recipes.component.html',
    styleUrls: ['./recipes.component.scss'],
    providers: [
        ManufacturerServiceProvider,
    ]
})
export class RecipesComponent extends BaseAdminListComponent implements OnInit {

    recipeQuery = '';

    constructor(
        protected service: ManufacturerService,
    ) {
        super();
    }

    getColumnDefinitions(): Array<IColumnDefinition> {
        return [
            {
                name: 'ID',
                prop: 'id',
                sortable: true,
                filter: true,
            },
            {
                name: 'Name',
                prop: 'name',
                sortable: true,
                filter: true,
            },
            {
                name: 'Description',
                prop: 'description',
                sortable: true,
                filter: true,
            },
        ];
    }

    protected refreshFunction(showInactive?: boolean): void {
        this.loading = true;
        this.subscribe(this.service.get(
            ((showInactive) ? this.recipeQuery + '&show_deleted=1' : this.recipeQuery),
            true
        ), (response) => {
            this.items = response.data;
            this.loading = false;
        });
    }

    ngOnInit(): void {
        this.columns = this.getColumnDefinitions();
        this.filterColumns = this.getFilterColumns();
        this.subscribe(
            this.service.get(
                this.recipeQuery,
                true
            ), (response) => {
                this.items = response.data;
                this.loading = false;
            }
        );
    }
}

import { NgModule } from '@angular/core';
import {CanViewDirective} from './can-view.directive';
import {HasPermissionDirective} from './has-permission.directive';

@NgModule({
    imports: [
    ],
    providers: [
    ],
    declarations: [
        CanViewDirective,
        HasPermissionDirective,
    ],
    exports: [
        CanViewDirective,
        HasPermissionDirective,
    ]
})
export class AclModule {}

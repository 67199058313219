import {Injectable} from '@angular/core';

// const themePanera = require('@shared/styles/themes/theme-novo_theme_panera.scss');
// const themePurple = require('../styles/themes/theme-novo_theme_purple.scss');
// const themeDark = require('../styles/themes/theme-novo_theme_dark.scss');
// const themeOrange = require('../styles/themes/theme-novo_theme_orange.scss');
// const themeGreen = require('../styles/themes/theme-novo_theme_green.scss');
// const themeRed = require('../styles/themes/theme-novo_theme_red.scss');
// const themeNovoBlue = require('../styles/themes/theme-novo_theme_blue.scss');
// const themeBlue = require('../styles/themes/theme-blue.scss');
// const themeCyan = require('../styles/themes/theme-novo_theme_cyan.scss');


@Injectable()
export class ThemesService {

    styleTag: any;

    constructor(
    ) {
        // configService.get('', true).subscribe(config => {
        //     if (typeof(config['theme']) !== 'undefined' && config['theme'].length > 0) {
        //         setTimeout(() => {
        //             this.createStyle();
        //             this.setTheme(config['theme']);
        //         }, 0);
        //     }
        // });
    }

    public createStyle() {
        const head = document.head || document.getElementsByTagName('head')[0];
        this.styleTag = document.createElement('style');
        this.styleTag.type = 'text/css';
        this.styleTag.id = 'appthemes';
        head.appendChild(this.styleTag);
    }

    setTheme(name) {
        // switch (name) {
        //     case 'novo_theme_panera':
        //         return this.injectStylesheet(themePanera);
        //     case 'novo_theme_purple':
        //         return this.injectStylesheet(themePurple);
        //     case 'novo_theme_dark':
        //         return this.injectStylesheet(themeDark);
        //     case 'novo_theme_orange':
        //         return this.injectStylesheet(themeOrange);
        //     case 'novo_theme_green':
        //         return this.injectStylesheet(themeGreen);
        //     case 'novo_theme_red':
        //         return this.injectStylesheet(themeRed);
        //     case 'novo_theme_blue':
        //         return this.injectStylesheet(themeNovoBlue);
        //     case 'novo_theme_cyan':
        //         return this.injectStylesheet(themeCyan);
        //     case 'blue':
        //         return this.injectStylesheet(themeBlue);
        //     default:
        //         return this.injectStylesheet(themeNovoBlue);
        // }
    }

    injectStylesheet(css) {
        this.styleTag.innerHTML = css;
    }

}

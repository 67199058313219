import { Injectable } from '@angular/core';

interface ILang {
    code: string;
    text: string;
}

@Injectable()
export class TranslatorService {

    private defaultLanguage = 'en';

    private availablelangs = [
        { code: 'en', text: 'English' },
        { code: 'es_AR', text: 'Spanish' }
    ];

    // constructor(public translate: TranslateService) {
    //
    //     if (!translate.getDefaultLang())
    //         translate.setDefaultLang(this.defaultLanguage);
    //
    //     this.useLanguage();
    //
    // }

    // useLanguage(lang: string = null) {
    //     this.translate.use(lang || this.translate.getDefaultLang());
    // }

    getAvailableLanguages(): ILang[] {
        return this.availablelangs;
    }

}

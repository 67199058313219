<div class="row pt-sm">
    <div class="col-md-12">
        <div>
            <input type="file" (change)="onChange($event)" />
            <div *ngIf="settings.app.logo" class="mt ">
                <img [src]="settings.app.logo" class="img-thumbnail" title="Logo" />
                <button class="btn btn-danger ml" title="Remove" (click)="reset()">Reset</button>
            </div>
        </div>
    </div>
</div>
<app-form-template
  [title]="title + ' Recipe' + (recipe && recipe['id'] ? ': ' + recipe['name'] : '')"
  submitText="Submit"
  [onSubmit]="submitCallback"
  [canSubmit]="canSubmit"
  [onCancel]="cancelCallback"
  [cancelText]="cancelButtonText"
  style="width: 100%;"
>
  <app-form
    formName="recipe"
    [forceShowValidation]="forceShowValidation"
    (updated)="onFormUpdate($event);onDataLoad();"
  ></app-form>
</app-form-template>

import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';

@Component({
    selector: 'app-datatable-template',
    templateUrl: './template.component.html',
    styleUrls: ['./template.component.scss'],
})
export class TemplateComponent implements OnInit {
    @ViewChild('buttonsLinkTpl', {static: true}) buttonsLinkTpl: TemplateRef<any>;

    @Input() title: string;
    @Input() addButtonText: string;
    @Input() addButtonPermission: string;
    @Input() showAdminButtons = true;
    @Input() loading: boolean;
    @Input() rows: Array<any>;
    @Input() columns: Array<any>;
    @Input() filterColumns: Array<any>;
    @Input() allowShowInactive: boolean;
    @Input() refresh: (event) => {};
    @Input() onFiltered: (event) => {};
    @Input() onFilterModeChanged: (event) => {};
    @Input() activeProperty: string;
    @Input() idProperty: string;
    @Input() nameProperty: string;

    @Output() restore = new EventEmitter();
    @Output() delete = new EventEmitter();

    ngOnInit(): void {
        if (!this.showAdminButtons) { return; }
        this.columns.unshift({
            name: '',
            cellTemplate: this.buttonsLinkTpl,
            sortable: false,
            maxWidth: 120
        });
    }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl} from '@angular/forms';
import {BaseComponent} from '../../Base';
import {ConfigService, ConfigServiceProvider} from '@shared/services/config.service';
import {SettingsService} from '../../../settings/settings.service';

@Component({
    selector: 'image-upload-single',
    templateUrl: './image-upload-single.component.html',
    styleUrls: ['./image-upload-single.component.scss'],
    providers: [
        ConfigServiceProvider,
    ]
})
export class ImageUploadSingleComponent extends BaseComponent implements OnInit {

    @Output() updated = new EventEmitter();
    @Input() selectedValue: Array<object>;
    @Input() ctrl: FormControl = new FormControl({});
    value = '';

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        private service: ConfigService,
        public settings: SettingsService,
    ) {
        super();
    }

    onChange(event): void {
        if (typeof (event.target.files[0]) !== 'undefined') {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.updated.emit(reader.result);
            };
        }
    }

    reset(): void {
        this.selectedValue = null;
        this.updated.emit({});
        this.value = '';
    }

    ngOnInit(): void {
        this.updated.emit({});
    }
}

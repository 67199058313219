<mat-form-field
    fxFlex="100%"
    appearance="outline"
>
    <mat-label>{{ placeholder }}</mat-label>
    <mat-select
        [formControl]="ctrl"
        [disabled]="disabled"
        [multiple]="multiple"
    >
        <mat-option
            *ngFor="let item of items"
            [value]="item['id']"
        >
            {{ item.text }}
        </mat-option>
    </mat-select>
</mat-form-field>

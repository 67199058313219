import {
    AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild,
    ViewContainerRef
} from '@angular/core';
import {Router} from '@angular/router';
import {BaseComponent} from '../Base';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-datatable',
    templateUrl: './datatable.component.html',
    styleUrls: ['./datatable.component.scss'],
})
export class DatatableComponent extends BaseComponent implements AfterViewInit {

    @Input() loading = false;
    @Input() rows = [];
    @Input() columns = [];
    @Input() filterColumns = [];
    @Input() title: string;
    @Input() addButtonText: string;
    @Input() addButtonPermission: string;
    @Input() importButtonText: string;
    @Input() structureButtonText: string;
    @Input() addTalentModelButtonText: string;
    @Input() updateTalentReportButtonText: string;
    @Input() allowShowInactive: boolean;
    @Input() hidden: boolean;
    @Input() headerContent: TemplateRef<any>;
    @Output() refresh = new EventEmitter();
    @Output() filtered = new EventEmitter();
    @Output() sort = new EventEmitter();
    @Output() filterModeChanged = new EventEmitter();
    @Input() paginationCount: number;

    @ViewChild('headerContent', {read: ViewContainerRef, static: false}) headerContentContainer;
    @ViewChild('table', {static: false}) table: DatatableComponent;

    showInactive = false;
    filter = '';
    offset = 0;
    columnDropdownOpen = false;
    filterColumnName = 'All Columns';
    defaultField = {
        prop: false,
        text: 'All Columns',
    };
    selected = this.defaultField;

    filterDebounce: Subject<string> = new Subject<string>();

    constructor(
        private vcRef: ViewContainerRef,
        private router: Router,
    ) {
        super();
    }

    routeNavigate(url): void {
        this.router.navigate([this.router.url + url]);
    }

    add(): void {
        this.router.navigate([this.router.url + '/add']);
    }

    onFilter(event): void {
        this.table.offset = 0;
        this.filterDebounce.next(event);
    }

    onColumnSort(event): void {
        if (!this.title) {
            return;
        }
    }

    import(): void {
        this.router.navigate([this.router.url + '/import']);
    }

    back(): void {
        this.router.navigate(['/admin']);
    }

    ngAfterViewInit(): void {
        if (typeof(this.headerContentContainer) !== 'undefined' && typeof(this.headerContent) !== 'undefined') {
            this.headerContentContainer.createEmbeddedView(this.headerContent);
        }
    }

}

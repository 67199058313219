import {Subject, Subscription} from 'rxjs';
import { Event } from './Event';
import {Injectable} from '@angular/core';

@Injectable()
export class EventBus {

    constructor(
        private subject: Subject<Event>
    ) {
    }

    public $emit(event: Event): void {
        this.subject.next(event);
    }

    public $on(eventName: string, callback: (data: any) => void): Subscription {
        return this.subject.subscribe((event => {
            if (event.name === eventName) {
                callback(event.data);
            }
        }));
    }
}

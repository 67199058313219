import { Injectable } from '@angular/core';
import {BaseApi} from '@core/api/BaseApi';
import {environment} from '../../environments/environment';
import {Observable, ReplaySubject} from 'rxjs';
import {HttpHeaders, HttpClient} from '@angular/common/http';

@Injectable()
export class AuthService extends BaseApi {
  protected baseUrl = environment.api.authUrl;
  protected resourcePath = '';
  private token: any;

  constructor(
      protected http: HttpClient,
  ) {
    super(http);
  }

  refresh(): Observable<any> {
    const headers = new HttpHeaders();
    headers.set('Authorization', window.localStorage.getItem('auth'));
    return this.http.get(environment.api.authUrl + 'refresh', {
      headers: headers
    });
  }

  post(path: string = '', data, onSuccess?, onError?): Observable<any> {
    const subject = new ReplaySubject(1);
    this.http.post(this.baseUrl + path, data).subscribe(
        (typeof (onSuccess) !== 'undefined') ? onSuccess : res => subject.next(res),
        (typeof(onError) !== 'undefined') ? onError : err => subject.next(err)
    );
    return subject.asObservable();
  }

  public setToken(token: {[t: string]: any}): void {
    if (typeof(token) === 'undefined') {
      this.token = token;
    }
  }

    public logout(): void {
        this.http.get(`${this.baseUrl}/logout`).subscribe(() => {
            window.localStorage.clear();
            window.location.assign('/login');
        });
    }

}

import {Component, Input} from '@angular/core';
import {BaseComponent} from '../../../Base';
import {FormControl} from '@angular/forms';
import {FormValidation} from '../../../../validation/Form';

@Component({
    selector: 'sub-form-errors',
    templateUrl: './errors.component.html',
    styleUrls: ['./errors.component.scss']
})
export class SubFormErrorsComponent extends BaseComponent {

    @Input() ctrl: FormControl;
    @Input() name: string;
    @Input() validationMessages = {};
    @Input() forceShowValidation = false;

    isShown = FormValidation.isValidationShown;

    getErrors() {
        if (this.ctrl.errors) {
            return Object.keys(this.ctrl.errors);
        }
        return [];
    }

}

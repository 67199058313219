import {Component, OnInit} from '@angular/core';
import {BaseAdminListComponent} from '@core/component/datatable/BaseAdminList';
import {IColumnDefinition} from '@shared/interfaces/IColumnDefinition';
import {UserService, UserServiceProvider} from '../../../../@shared/services/user.service';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    providers: [
        UserServiceProvider,
    ]
})
export class UsersComponent extends BaseAdminListComponent implements OnInit {

    recipeQuery = '';

    constructor(
        protected service: UserService,
    ) {
        super();
    }

    getColumnDefinitions(): Array<IColumnDefinition> {
        return [
            {
                name: 'ID',
                prop: 'id',
                sortable: true,
                filter: true,
            },
            {
                name: 'First Name',
                prop: 'first_name',
                sortable: true,
                filter: true,
            },
            {
                name: 'Last Name',
                prop: 'last_name',
                sortable: true,
                filter: true,
            },
            {
                name: 'Email',
                prop: 'email',
                sortable: true,
                filter: true,
            },
        ];
    }

    protected refreshFunction(showInactive?: boolean): void {
        this.loading = true;
        this.subscribe(this.service.get(
            ((showInactive) ? this.recipeQuery + '&show_deleted=1' : this.recipeQuery),
            true
        ), (response) => {
            this.items = response.data;
            this.loading = false;
        });
    }

    ngOnInit(): void {
        this.columns = this.getColumnDefinitions();
        this.filterColumns = this.getFilterColumns();
        this.subscribe(
            this.service.get(
                this.recipeQuery,
                true
            ), (response) => {
                this.items = response.data;
                this.loading = false;
            }
        );
    }
}

import { Injectable } from '@angular/core';
import {BaseApi} from '@core/api/BaseApi';
import {HttpClient} from '@angular/common/http';

@Injectable()

export class ConfigService extends BaseApi {
  protected resourcePath = '/configs';
  put(data, onSuccess?, onError?) {
    return this.patch(0, data, onSuccess, onError);
  }
}

export const ConfigServiceProvider = {
  provide: ConfigService,
  useFactory: serviceFactory,
  deps: [HttpClient]
};

export function serviceFactory(http: HttpClient) {
  return new ConfigService(http);
}

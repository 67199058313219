<div *ngIf="immutable" class="alert alert-warning">
    <p>{{immutable}}</p>
</div>
<form [formGroup]="ngForm" [id]="isModal ? 'modal_admin_form' : 'admin_form'" class="form-horizontal">
    <ng-container *ngFor="let element of form" >
        <hr *ngIf="element.type === 'horizontalrule'"/>
        <h5 *ngIf="element.type === 'sectionheading'">{{element.label}}</h5>
        <div *ngIf="element.type !== 'horizontalrule' && element.type !== 'sectionheading' && (!element.options || !element.options.hidden || isVisible(element.options.hidden))" class="form-group" [ngStyle]="{
            'margin-bottom': (isModal && ngForm.controls[element.name].invalid && ngForm.controls[element.name].touched) ? '0' : '15px'
        }">
            <form-element
                [isModal]="isModal"
                [element]="element"
                [ctrl]="ngForm.controls[element.name]"
                [validationMessages]="validationMessages[element.name] || {}"
                [forceShowValidation]="forceShowValidation"
                (updated)="updateControl(element.name, $event)"
                (validation)="setValidationState($event, element)"
            ></form-element>
        </div>
    </ng-container>
</form>
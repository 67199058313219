import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseApi} from '@core/api/BaseApi';


@Injectable()
export class CarouselService extends BaseApi {
  protected resourcePath = '/carousels';
}

export const CarouselServiceProvider = {
  provide: CarouselService,
  useFactory: carouselServiceFactory,
  deps: [HttpClient]
};

export function carouselServiceFactory(http: HttpClient): CarouselService {
  return new CarouselService(http);
}

<div *ngIf="!hidden" class="panel panel-default">
    <div class="panel-heading">
        <div class="input-group input-group-sm col-xs-6">
            <div *ngIf="filterColumns.length > 0" class="input-group-btn">
                <div
                    class="btn-group btn-group-sm"
                    dropdown
                >
                    <button
                        class="btn btn-sm btn-primary br0"
                        dropdownToggle
                        type="button"
                        style="font-size: 13px;"
                    >{{filterColumnName}} <i class="fa fa-caret-down ml-lg"></i></button>
                    <ul
                        *dropdownMenu
                        class="dropdown-menu"
                        role="menu"
                    >
                        <li role="menuitem">
                            <a class="dropdown-item" (click)="filterModeChanged.next(false); filterColumnName = 'All Columns';">All Columns</a>
                        </li>
                        <li
                            role="menuitem"
                            *ngFor="let column of filterColumns"
                        >
                            <a class="dropdown-item" (click)="filterModeChanged.next(column['prop']); filterColumnName = column['text'];">{{column['text']}}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <input
                type="text"
                class="form-control input-sm"
                placeholder="Filter Results"
                [(ngModel)]="filter"
                (ngModelChange)="filtered.next($event);onFilter($event);"
            />
        </div>
    </div>
     <div class="panel-body">
         <div class="row">
             <div class="col-xs-12">
                 <ngx-datatable
                     #table
                     class="material striped big-loading-text"
                     *ngIf="!loading"
                     [rows]="rows"
                     [columns]="columns"
                     [columnMode]="'force'"
                     [limit]="20"
                     [rowHeight]="'50'"
                     [footerHeight]="35"
                     (sort)="onColumnSort($event)"
                 >
                 </ngx-datatable>
                 <div *ngIf="loading" class="text-center">
                     <div class="sk-three-bounce">
                         <div class="sk-child sk-bounce1"></div>
                         <div class="sk-child sk-bounce2"></div>
                         <div class="sk-child sk-bounce3"></div>
                     </div>
                 </div>
             </div>
         </div>
     </div>
</div>

import {Component, EventEmitter, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {Observable} from 'rxjs';
import {ServiceLocator} from '../di/ServiceLocator';
import {EventBus} from '../event/EventBus';
import {Event} from '../event/Event';
import {AclService} from '@shared/services/acl.service';
import {environment} from '../../environments/environment';

@Component({
    template: '',
})
export class BaseComponent implements OnDestroy {
  private subscriptions: Subscription[] = [];
  private msgBus: EventBus;
  protected acl: object;
  protected aclLoad = new EventEmitter();

  constructor() {
    this.msgBus = ServiceLocator.injector.get(EventBus);
    // if (window.localStorage.getItem('auth') !== null) {
    //   this.subscribe(ServiceLocator.injector.get(AclService).get('acl'), acl => {
    //     this.acl = acl;
    //     this.aclLoad.next(acl);
    //   });
    // }
  }

  public getObjectKeys(obj: object): string[] {
    return Object.keys(obj);
  }

  protected trackAnalytics(action: string, properties?: object): void {
    // return this.analyticsService.trackAnalytics(action, properties);
  }

  protected subscribe(source: Observable<any>, callback: (data: any) => void, error?: (data: any) => void): number {
    const sub = (typeof(error) !== 'undefined') ? source.subscribe(callback, error) : source.subscribe(callback);
    this.subscriptions.push(sub);
    return this.subscriptions.length - 1;
  }

  protected unsubscribe(index): void {
    const subs = this.subscriptions.splice(index, 1);
    return typeof(subs[0]) !== 'undefined' && subs[0].unsubscribe();
  }

  protected $emit(event: Event): void {
    this.msgBus.$emit(event);
  }

  protected $on(eventName: string, callback: (data: any) => void): void {
    this.subscriptions.push(this.msgBus.$on(eventName, callback));
  }

  log(item: any): void { // Log function to run from any component that extends BaseComponent
    if (environment.production !== true) {
      console.log(item);
    }
  }

  isDefined(item): boolean {
    return typeof(item) !== 'undefined';
  }

  isNull(item): boolean {
    return item === null;
  }

  isNaN(item): boolean {
    return isNaN(item);
  }

  goBack(): void {
    window.history.go(-1);
  }

  onCancel(): void {
    return window.history.go(-1);
  }

  ngOnDestroy(): void {
      for (const sub of this.subscriptions) {
          sub.unsubscribe();
      }
  }

  protected setColumnWidth(numColumns?: number): number {
    const columns = (typeof (numColumns) !== 'undefined') ? numColumns : 2;
    return (columns * 25) + 30;
  }

}

import {
    AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit,
    Output
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {BaseComponent} from '../../Base';
import {FormService} from '@shared/services/form.service';
import {FormServiceProvider} from './FormServiceProvider';
import {FormCache} from './FormCache';
import {FormUtils} from './FormUtils';
import {FormDataService} from './FormDataService';

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    providers: [
        FormServiceProvider
    ]
})
export class FormComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() formName: string;
    @Input() form = [];
    @Input() isModal = false;
    @Input() forceShowValidation = false;
    @Output() updated = new EventEmitter();

    ngForm: FormGroup = new FormGroup({});
    validationMessages = {};
    immutable: string;

    constructor(
        private formService: FormService,
        private formDataService: FormDataService,
        private cache: FormCache
    ) {
        super();
        this.ngForm['disabledFields'] = [];
    }

    generateForm(): void {
        const form = FormUtils.generateForm(this.form);
        this.ngForm = form.ngForm;
        this.ngForm.valueChanges.subscribe(() => {
            this.updated.emit(this.ngForm);
        });
        this.validationMessages = form.validationMessages;
        this.updated.emit(this.ngForm);
    }

    updateControl(name, event): void {
        this.ngForm.controls[name].setValue(event);
    }

    setValidationState(event, element): void {
        this.ngForm.controls[element.name].setErrors((event) ? null : {subFormInvalid: true});
    }

    private setForm(form): void {
        this.form = form;
        this.generateForm();
    }

    isVisible(hidden: object): boolean {
        for (const field in hidden) {
            if (typeof(this.ngForm.controls[field]) === 'undefined' || this.ngForm.controls[field].value !== hidden[field]) {
                return false;
            }
        }
        return true;
    }

    ngOnInit(): void {
        const cached = this.cache.fetch(this.formName);
        if (cached !== false) {
            this.setForm(cached);
            return;
        }
        this.subscribe(this.formService.get('/' + this.formName), res => {
            this.cache.cache(this.formName, res);
            this.setForm(res);
        });
    }

    ngAfterViewInit(): void {
        this.subscribe(this.formDataService.getEntity(), data => {
            const immutableMetadata = data.immutable;
            if (typeof(immutableMetadata) !== 'undefined') {
                this.immutable = (this.isDefined(immutableMetadata.message))
                    ? immutableMetadata.message
                    : 'Some fields are disabled because this record is in use.';
                delete(data.immutable);
            }
            for (const field in data) {
                if (typeof(this.ngForm.controls[field]) !== 'undefined') {
                    if (
                        typeof(immutableMetadata) !== 'undefined' &&
                        ((
                            typeof(immutableMetadata['allowedFields']) === 'undefined' ||
                            immutableMetadata['allowedFields'].indexOf(field) === -1
                        ) ||
                        (
                            typeof(immutableMetadata['restrictMapFields']) !== 'undefined' &&
                            immutableMetadata['restrictMapFields'].indexOf(field) !== -1
                        ))
                    ) {
                        this.ngForm.controls[field]['disabledStyle'] = true;
                    }
                    this.ngForm.controls[field].patchValue(data[field]);
                }
            }
        });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.formDataService.reset();
    }

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseSelectComponent} from '../base-select/base-select.component';

@Component({
    selector: 'file-input',
    templateUrl: './file.component.html',
    styleUrls: ['./file.component.scss']
})
export class FileInputComponent extends BaseSelectComponent implements OnInit {

    @Input() placeholder: string;
    @Output() updated = new EventEmitter();

    onFileSelect(event): void {
        this.selectedValue = event.target.files[0];
        this.ctrl.patchValue(this.selectedValue);
        this.updated.emit(this.selectedValue);
    }

    ngOnInit(): void {
        this.ctrl.valueChanges.subscribe(value => {
            this.selectedValue = value;
        });
    }

}

import {Injector, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import {DashboardModule} from './main/dashboard/dashboard.module';
import {SharedModule} from '../@shared/shared.module';
import {CoreModule} from '../@core/core.module';
import {EventBus} from '../@core/event';
import {Subject} from 'rxjs';
import {ServiceLocator} from '../@core/di/ServiceLocator';
import {MatDialogModule} from '@angular/material/dialog';
import {AuthInterceptor} from '../@core/api/auth.interceptor';
import {IsAuthenticated} from '../@core/acl/IsAuthenticated';
import {Location} from '@angular/common';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {AuthService} from '../@shared/services/auth.service';
import {ProfileService} from '../@shared/services/profile.service';
import {AdminModule} from './main/admin/admin.module';

const appRoutes: Routes = [
    {
        path        : 'login',
        loadChildren: () => import('./main/authentication/login/login.module').then(m => m.LoginModule),
    },
    {
        path        : 'home',
        pathMatch   : 'full',
        canLoad     : [IsAuthenticated],
        canActivateChild: [IsAuthenticated],
        loadChildren: () => import('./main/dashboard/dashboard.module').then(m => m.DashboardModule),
    },
    {
        path        : '**',
        canLoad     : [IsAuthenticated],
        redirectTo  : 'home',
    },
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        MatSnackBarModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,

        // Custom modules
        CoreModule,
        SharedModule,

        // App modules
        LayoutModule,
        DashboardModule,
        AdminModule,
    ],
    providers: [
        EventBus,
        Subject,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        AuthService,
        ProfileService,
        IsAuthenticated,
        Location,
    ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule {
    constructor(
        private injector: Injector
    ) {
        ServiceLocator.injector = this.injector;
    }
}

import {FormValidation} from '../../../validation/Form';
import {FormControl, FormGroup} from '@angular/forms';

export class FormUtils {

    private static getFormValidators(element) {
        const validation = new FormValidation();
        return {
            messages: validation.getValidationMessages(element.validators),
            validators: validation.getValidators(element.validators)
        };
    }

    public static generateForm(jsonForm): {ngForm: FormGroup, validationMessages: object} {
        const form = {};
        const validationMessages = {};
        for (let i = 0; i < jsonForm.length; i++) {
            const element = jsonForm[i];
            const validation = this.getFormValidators(element);
            form[element.name] = new FormControl(element.options.default || this.getDefault(element.type), validation.validators);
            validationMessages[element.name] = validation.messages;
        }
        return {
            ngForm: new FormGroup(form),
            validationMessages: validationMessages
        };
    }

    public static getDefault(type: string) {
        switch (type) {
            case 'subform':
                return [];
            case 'check':
            case 'radiobuttongroup':
                return false;
            case 'text':
            case 'email':
            case 'password':
            default:
                return '';
        }
    }

}

import {Component, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import {DashboardService} from '../../../@shared/services/dashboard.service';
import {ProfileService} from '../../../@shared/services/profile.service';
import {BaseComponent} from '../../../@core/component/Base';

@Component({
    selector     : 'app-dashboard',
    templateUrl  : './dashboard.component.html',
    styleUrls    : ['./dashboard.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations,
})
export class DashboardComponent extends BaseComponent
{

    dateNow = Date.now();

    /**
     * Constructor
     *
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {DashboardService} dashboardService
     * @param {ProfileService} profile
     */
    constructor(
        private _fuseSidebarService: FuseSidebarService,
        public readonly dashboardService: DashboardService,
        public readonly profile: ProfileService,
    )
    {
        super();

        setInterval(() => {
            this.dateNow = Date.now();
        }, 1000);

    }

    formatOrdersByCategoryValue({name, value}): string {
        return `${name}: $${(+value).toFixed(2)}`;
    }

    formatBudgetTick(value): string {
        return `$${(+value).toFixed(2)}`;
    }
}


import {Component, OnInit} from '@angular/core';
import {BaseFormComponent} from '@core/component/form/form/BaseForm';
import {ActivatedRoute} from '@angular/router';
import {FormDataService, FormDataServiceProvider} from '@core/component/form/form/FormDataService';
import {UserService, UserServiceProvider} from '@shared/services/user.service';

@Component({
    selector: 'app-edit-manufacturers',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    providers: [
        UserServiceProvider,
        FormDataServiceProvider,
    ]
})
export class UsersEditComponent extends BaseFormComponent implements OnInit {

    title = 'Edit';
    disabled: boolean;
    recipe: object;

    constructor(
        protected service: UserService,
        protected route: ActivatedRoute,
        protected formDataService: FormDataService
    ) {
        super();
    }

    onDataLoad(): void {
        if (!this.loaded
            && typeof(this.form) !== 'undefined'
            && typeof(this.recipe) !== 'undefined'
        ) {
            this.loaded = true;
            const entity = this.recipe;
            this.entity = entity;
            this.formDataService.update(entity);
        }
    }

    loadEntity(id): void {
        this.subscribe(
            this.service.get('?q=id:' + id, true),
            (response) => {
                this.recipe = response.data[0];
                this.onDataLoad();
            }
        );
    }

    ngOnInit(): void {
        this.subscribe(this.route.params, params => {
            if (typeof(params['id']) !== 'undefined') {
                this.loadEntity(params['id']);
            } else {
                this.title = 'Add';
                this.formDataService.update({});
            }
        });
    }
}

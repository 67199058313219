import {
    AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild,
    ViewContainerRef
} from '@angular/core';
import {Router} from '@angular/router';
import {BaseComponent} from '../Base';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-datatable-simple',
    templateUrl: './datatable-simple.component.html',
    styleUrls: ['./datatable-simple.component.scss']
})
export class DatatableSimpleComponent extends BaseComponent implements AfterViewInit {

    @Input() loading = false;
    @Input() rows = [];
    @Input() columns = [];
    @Input() filterColumns = [];
    @Input() title: string;
    @Input() hidden: boolean;
    @Input() headerContent: TemplateRef<any>;
    @Input() allowShowInactive: boolean;
    @Output() refresh = new EventEmitter();
    @Output() filtered = new EventEmitter();
    @Output() sort = new EventEmitter();
    @Output() filterModeChanged = new EventEmitter();

    @ViewChild('headerContent', {read: ViewContainerRef, static: false}) headerContentContainer;
    @ViewChild('table', {read: ViewContainerRef, static: false}) table: DatatableSimpleComponent;

    filter = '';
    offset = 0;
    columnDropdownOpen = false;
    filterColumnName = 'All Columns';

    filterDebounce: Subject<string> = new Subject<string>();

    constructor(
        private vcRef: ViewContainerRef,
        private router: Router
    ) {
        super();
    }

    add(): void {
        this.router.navigate([this.router.url + '/add']);
    }

    onFilter(event): void {
        this.table.offset = 0;
        this.filterDebounce.next(event);
    }

    onColumnSort(event): void {
        this.trackAnalytics(
            'sorted ' + this.title + ' list',
            {
                category: 'admin',
                label: event.column.name + ' ' + event.newValue
            }
        );
    }

    ngAfterViewInit(): void {
        if (typeof(this.headerContentContainer) !== 'undefined' && typeof(this.headerContent) !== 'undefined') {
            this.headerContentContainer.createEmbeddedView(this.headerContent);
        }
    }

}

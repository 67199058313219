import {Routes} from '@angular/router';
import {FuseNavigationItem} from '../../../@fuse/types';
import {IsAuthenticated} from '../../../@core/acl/IsAuthenticated';
import {RecipesComponent} from './recipes/recipes.component';
import {RecipesEditComponent} from './recipes/edit/recipes.component';
import {IngredientsComponent} from './ingredients/ingredients.component';
import {IngredientsEditComponent} from './ingredients/edit/ingredients.component';
import {UsersComponent} from './users/users.component';
import {UsersEditComponent} from './users/edit/users.component';

export const AdminRoutes: Routes = [
    {
        path: 'admin',
        canActivateChild: [IsAuthenticated],
        children: [
            {
                path: 'recipes',
                children: [
                    {path: '', component: RecipesComponent},
                    {path: ':id/edit', component: RecipesEditComponent},
                    {path: 'add', component: RecipesEditComponent},
                ],
            },
            {
                path: 'ingredients',
                children: [
                    {path: '', component: IngredientsComponent},
                    {path: ':id/edit', component: IngredientsEditComponent},
                    {path: 'add', component: IngredientsEditComponent},
                ],
            },
            {
                path: 'users',
                children: [
                    {path: '', component: UsersComponent},
                    {path: ':id/edit', component: UsersEditComponent},
                    {path: 'add', component: UsersEditComponent},
                ],
            },
        ],
    },
];

export const AdminNavigation: Array<FuseNavigationItem> = [
    {
        id: 'surveys',
        title: 'Surveys',
        translate: 'NAV.SURVEYS.TITLE',
        type: 'item',
        icon: 'page',
        url: '/admin/surveys',
    },
    {
        id: 'manufacturers',
        title: 'Questions',
        translate: 'NAV.QUESTIONS.TITLE',
        type: 'item',
        icon: 'page',
        url: '/admin/questions',
    },
    {
        id: 'recipients',
        title: 'Recipients',
        translate: 'NAV.RECIPIENTS.TITLE',
        type: 'item',
        icon: 'page',
        url: '/admin/recipients',
    },
];

import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'change-password-btn',
    templateUrl: './change-password-btn.component.html',
    styleUrls: ['./change-password-btn.component.scss']
})
export class ChangePasswordBtnComponent implements OnInit {

    ngOnInit() {}

}

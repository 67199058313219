import {Component, OnInit} from '@angular/core';
import {BaseFormComponent} from '@core/component/form/form/BaseForm';
import {ActivatedRoute} from '@angular/router';
import {FormDataService, FormDataServiceProvider} from '@core/component/form/form/FormDataService';
import {ManufacturerService, ManufacturerServiceProvider} from '@shared/services/manufacturer.service';

@Component({
    selector: 'app-edit-manufacturers',
    templateUrl: './recipes.component.html',
    styleUrls: ['./recipes.component.scss'],
    providers: [
        ManufacturerServiceProvider,
        FormDataServiceProvider,
    ]
})
export class RecipesEditComponent extends BaseFormComponent implements OnInit {

    title = 'Edit';
    disabled: boolean;
    recipe: {[t: string]: any};

    fields: string = '&fields=recipe.id,recipe.name,recipe.description,recipe.instructions,array:ingredients=(ingredient.id)';

    constructor(
        protected service: ManufacturerService,
        protected route: ActivatedRoute,
        protected formDataService: FormDataService
    ) {
        super();
    }

    onDataLoad(): void {
        if (!this.loaded
            && typeof(this.form) !== 'undefined'
            && typeof(this.recipe) !== 'undefined'
        ) {
            this.loaded = true;
            const { recipeId, recipeName, recipeDescription, recipeInstructions, ingredients } = this.recipe;
            this.entity = {
                id: recipeId,
                name: recipeName,
                description: recipeDescription,
                instructions: recipeInstructions,
                ingredients: JSON.parse(ingredients),
            };
            this.formDataService.update(this.entity);
        }
    }

    loadEntity(id): void {
        this.subscribe(
            this.service.get('?q=id:' + id + this.fields, true),
            (response) => {
                this.recipe = response.data[0];
                this.onDataLoad();
            }
        );
    }

    ngOnInit(): void {
        this.subscribe(this.route.params, params => {
            if (typeof(params['id']) !== 'undefined') {
                this.loadEntity(params['id']);
            } else {
                this.title = 'Add';
                this.formDataService.update({});
            }
        });
    }
}

import {Injectable} from '@angular/core';
import {
    HttpEvent,
    HttpHandler, HttpHeaders,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ServiceLocator} from '../di/ServiceLocator';
import {Router} from '@angular/router';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (window.localStorage.getItem('authorization') !== null && !request.headers.has('Authorization')) {
            request = request.clone({
                headers: new HttpHeaders({
                    Authorization: window.localStorage.getItem('authorization')
                }),
            });
        }
        return next
            .handle(request)
            .pipe(
                tap((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        if (event.status === 401) {
                            ServiceLocator.injector.get(Router).navigate(['/login']);
                        }
                        if (event.headers.has('Authorization')) {
                            window.localStorage.removeItem('authorization');
                            window.localStorage.setItem('authorization', event.headers.get('Authorization'));
                        }
                    }
                })
            );
    }
}

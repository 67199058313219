// tslint:disable:no-bitwise
import { Injectable } from '@angular/core';
import {FormControl, Validators} from '@angular/forms';

@Injectable()
export class FormValidation extends Validators {

    public validators = [];

    public static alnum(allowEmpty = false): (FormControl) => any {
        return (c: FormControl) => {
            return (allowEmpty && c.value.length === 0 || /^[a-z0-9_\s]*$/i.test(c.value)) ? null : {'alnum': true};
        };
    }

    public static regex(regex: string, allowEmpty = false): (FormControl) => any {
        return (c: FormControl) => {
          return (allowEmpty && c.value.length === 0 || (new RegExp(regex)).test(c.value) ? null : {'regex': true});
        };
    }

    public static phoneNumber(c: FormControl): any {
        return /^[\d\(\)\-\. ]*$/.test(c.value) ? null : {'phonenumber': true};
    }

    public static passwordStrength(allowEmpty = false): (FormControl) => any {
        return (c: FormControl) => {
            return allowEmpty && c.value.length === 0
                || (~~/[A-Z]/.test(c.value) + ~~/[a-z]/.test(c.value) + ~~/\d/.test(c.value) + ~~/\W/.test(c.value)) > 2
                ? null
                : {'passwordstrength': true};
        };
    }

    public static isValidationShown(ctrl: FormControl, validator: string): boolean {
        switch (validator) {
            case 'alnum':
            case 'regex':
            case 'phonenumber':
                return true;
            default:
                return ctrl.touched;
        }
    }

    public getValidators(validators): any[] {
        this.validators = [];
        for (const validator of validators) {
            this.getValidatorsFor(validator);
        }
        return this.validators;
    }

    getValidatorsFor(validator): void {
        const allowEmpty = typeof(validator.options['allowEmpty']) !== 'undefined' && validator.options['allowEmpty'] === true;
        switch (validator.type) {
            case 'presenceof':
                this.validators.push(Validators.required);
                break;
            case 'phonenumber':
                this.validators.push(FormValidation.phoneNumber);
                break;
            case 'passwordstrength':
                this.validators.push(FormValidation.passwordStrength(allowEmpty));
                break;
            case 'stringlength':
                this.getStringLengthValidators(validator);
                break;
            case 'alnum':
                this.validators.push(FormValidation.alnum(allowEmpty));
                break;
            case 'regex':
                this.validators.push(FormValidation.regex(validator.options.pattern.slice(1, -1)));
                break;
            case 'between':
                this.validators.push(FormValidation.min(validator.options.minimum));
                this.validators.push(FormValidation.max(validator.options.maximum));
                break;
        }
    }

    getStringLengthValidators(validator): void {
        if (typeof(validator.options.min) !== 'undefined') {
            this.validators.push(Validators.minLength(+validator.options.min));
        }
        if (typeof(validator.options.max) !== 'undefined') {
            this.validators.push(Validators.maxLength(+validator.options.max));
        }
    }

    getValidationMessages(validators): { [t: string]: string } {
        const messages = {};
        for (const validator of validators) {
            if (typeof(validator) !== 'undefined') {
                switch (validator.type) {
                    case 'stringlength':
                        if (typeof(validator.options.messageMinimum)) {
                            messages['minlength'] = validator.options['messageMinimum'];
                        }
                        if (typeof(validator.options.messageMaximum)) {
                            messages['maxlength'] = validator.options['messageMaximum'];
                        }
                        break;
                    case 'presenceof':
                        messages['required'] = validator.options.message;
                        break;
                    case 'between':
                        messages['min'] = validator.options.message;
                        messages['max'] = validator.options.message;
                        break;
                    default:
                        messages[validator.type] = validator.options.message;
                }
            }
        }
        return messages;
    }

}

import {Injector, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {FlexLayoutModule} from '@angular/flex-layout';

import {AuthService} from '@shared/services/auth.service';
import {ServiceLocator} from '@core/di/ServiceLocator';
import {AdminRoutes} from './admin.routing';
import {FormModule} from '@core/component/form/form.module';
import {SharedModule} from '@shared/shared.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {DatatableModule} from '../../../@core/component/datatable/datatable.module';
import {ConfirmationDialogComponent} from '../../../@core/component/datatable/BaseAdminList';
import {ProfileService} from '../../../@shared/services/profile.service';
import {RecipesComponent} from './recipes/recipes.component';
import {RecipesEditComponent} from './recipes/edit/recipes.component';
import {IngredientsComponent} from './ingredients/ingredients.component';
import {IngredientsEditComponent} from './ingredients/edit/ingredients.component';
import {UsersComponent} from './users/users.component';
import {UsersEditComponent} from './users/edit/users.component';

@NgModule({
    imports: [
        CommonModule,
        FormModule,
        SharedModule,
        RouterModule.forChild(AdminRoutes),
        MatIconModule,
        MatCardModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        NgxDatatableModule,
        FlexLayoutModule,
        DatatableModule,
    ],
    declarations: [
        RecipesComponent,
        RecipesEditComponent,
        IngredientsComponent,
        IngredientsEditComponent,
        UsersComponent,
        UsersEditComponent,
    ],
    providers: [
        AuthService,
        ProfileService,
    ],
    entryComponents: [
        ConfirmationDialogComponent,
    ],
})
export class AdminModule {
    constructor(
        private injector: Injector,
    ) {
        ServiceLocator.injector = this.injector;
    }
}

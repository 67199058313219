import { Injectable } from '@angular/core';
import {BaseApi} from '@core/api/BaseApi';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class UserService extends BaseApi {
  protected resourcePath = '/users';
}

export const UserServiceProvider = {
  provide: UserService,
  useFactory: usersServiceFactory,
  deps: [HttpClient]
};

export function usersServiceFactory(http: HttpClient) {
  return new UserService(http);
}

<input
    *ngIf="!selectedValue"
    type="file"
    class="form-control"
    (change)="onFileSelect($event)"
/>
<ng-container
    *ngIf="!!selectedValue"
>
    <input
        class="form-control"
        type="text"
        [value]="selectedValue.name || selectedValue"
        [disabled]="true"
    />
    <span
        class="pull-right input-button"
        [title]="'Remove File: ' + (selectedValue.name || selectedValue)"
        (click)="selectedValue = null; updated.emit(selectedValue)"
    >
        <i class="fa fa-remove fa-2x text-danger"></i>
    </span>
</ng-container>
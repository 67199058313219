import {Component, Input} from '@angular/core';
import {BaseComponent} from '../../../Base';
import {FormControl} from '@angular/forms';
import {FormValidation} from '../../../../validation/Form';

@Component({
    selector: 'form-control-errors',
    templateUrl: './errors.component.html',
    styleUrls: ['./errors.component.scss']
})
export class ErrorsComponent extends BaseComponent {

    @Input() ctrl: FormControl;
    @Input() name: string;
    @Input() validationMessages = {};
    @Input() forceShowValidation = false;
    @Input() isModal = false;

    isShown = FormValidation.isValidationShown;

    getErrors(): any {
        return Object.keys(this.ctrl.errors);
    }

    getErrorMessage(validator: string): string | null {
        const error = this.ctrl.errors[validator];
        return (error && error.hasOwnProperty('message'))
            ? error.message
            : this.validationMessages[validator] || null;
    }

}

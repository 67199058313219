type Permissions = {
    resource: string,
    action: 'CREATE' | 'READ' | 'UPDATE' | 'DELETE' | 'ALL' | string,
    scope: 'ALL' | 'GROUP' | 'PRACTICE' | 'SELF' | string,
};

export class AclUtils {

    public static PRIORITY = {
        ALL         : 3,
        GROUP       : 2,
        PRACTICE    : 1,
        SELF        : 0,
    };

    private static maxPermissionReducer(accumulator: number, currentValue: string): number {
        const currentPriority = AclUtils.PRIORITY[currentValue];
        return (currentPriority > accumulator) ? currentPriority : accumulator;
    }

    private static checkResource(resource, action: string, scope: string): boolean {
        const priorities = AclUtils.PRIORITY;
        if (action === '*') {
            return scope === '*'
                || resource[action] > Object.keys(resource)
                    .map(key => priorities[resource[key]])
                    .reduce(this.maxPermissionReducer, -1);
        }
        return (typeof(resource['*']) === 'undefined')
            ? (resource[action] !== undefined && priorities[resource[action]] > priorities[scope])
            : priorities[resource['*']] > priorities[scope];
    }

    public static parsePermissionsString(permissionsString: string): Permissions {
        const permissionsArray = permissionsString.split(':');
        return {
            resource: permissionsArray[0],
            action  : permissionsArray[1],
            scope   : permissionsArray[2],
        };
    }

    public static hasPermission(requiredPermissions: string, acl: {[t: string]: any }): boolean {
        const permissions: Permissions = AclUtils.parsePermissionsString(requiredPermissions);

        if (
            typeof(acl) === 'undefined' ||
            (typeof(acl['*']) === 'undefined' && typeof(acl[permissions.resource]) === 'undefined')
        ) {
            return false;
        }

        return AclUtils.checkResource(acl[permissions.resource], permissions.action, permissions.scope);
    }

}

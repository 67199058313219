import {NgModule} from '@angular/core';
import {FormComponent} from './form/form.component';
import {SharedModule} from '@shared/shared.module';
import {ElementComponent} from './element/element.component';
import {ComponentElementModule} from './element/component-element.module';
import {ErrorsComponent} from './element/errors/errors.component';
import {FormCache} from './form/FormCache';
import {BaseFormComponent} from './form/BaseForm';
import {InlineAddComponent} from '../input/inline-add/inline-add.component';
import {TemplateComponent as AdminTemplateComponent} from './template/template.component';
import {TemplateComponent as DatatableTemplateComponent} from '../datatable/template/template.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {RouterModule} from '@angular/router';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormInputsModule} from '../input/form-inputs.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCardModule} from '@angular/material/card';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {DatatableModule} from '../datatable/datatable.module';

@NgModule({
    imports: [
        FlexLayoutModule,
        RouterModule,
        SharedModule,
        DatatableModule,
        ComponentElementModule,
        NgxDatatableModule,
        FormInputsModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatCardModule,
        SharedModule,
    ],
    declarations: [
        BaseFormComponent,
        FormComponent,
        ElementComponent,
        ErrorsComponent,
        InlineAddComponent,
        AdminTemplateComponent,
        DatatableTemplateComponent,
    ],
    exports: [
        FormComponent,
        InlineAddComponent,
        AdminTemplateComponent,
        DatatableTemplateComponent,
        FormInputsModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatCardModule,
        SharedModule,
        DatatableModule,
    ],
    providers: [
        FormCache,
    ],
    entryComponents: [
        InlineAddComponent,
    ]
})
export class FormModule { }

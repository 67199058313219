<div class="page-layout simple fullwidth">
    <div class="content">
        <app-datatable
            class="material"
            [title]="title"
            [addButtonText]="addButtonText"
            [addButtonPermission]="addButtonPermission"
            [loading]="loading"
            [rows]="rows"
            [columns]="columns"
            [filterColumns]="filterColumns"
            [allowShowInactive]="allowShowInactive"
            (refresh)="refresh($event)"
            (filtered)="onFiltered($event)"
            (filterModeChanged)="onFilterModeChanged($event)"
        ></app-datatable>
    </div>
</div>

<ng-template #buttonsLinkTpl let-row="row" let-index="rowIndex">
    <div fxLayout="row" fxLayoutGap="4px">
        <button
            *ngIf="row[activeProperty]"
            mat-icon-button
            color="primary"
            [routerLink]="row[idProperty] + '/edit'"
            title="Edit"
        >
            <mat-icon>create</mat-icon>
        </button>
        <button
            *ngIf="row[activeProperty]"
            mat-icon-button
            color="warn"
            (click)="delete.emit({
                id: row[idProperty],
                name: row[nameProperty],
                index: index
            })"
            title="Delete"
        >
            <mat-icon>delete</mat-icon>
        </button>
        <button
            *ngIf="!row[activeProperty]"
            mat-icon-button
            color="primary"
            (click)="restore.emit({
            id: row[idProperty],
            name: row[nameProperty],
            index: index
          })"
            class="mat-button-xs"
            title="Restore"
        >
            <mat-icon>undo</mat-icon>
        </button>
    </div>
</ng-template>

import { Injectable } from '@angular/core';

@Injectable()
export class FormCache {
    forms = {};

    cache(name, data) {
        this.forms[name] = data;
    }

    fetch(name) {
        return (typeof(this.forms[name]) !== 'undefined') ? this.forms[name] : false;
    }
}


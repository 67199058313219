import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AclService} from './services/acl.service';
import {MatButtonModule} from '@angular/material/button';
import {MatOptionModule} from '@angular/material/core';
import {BadgeService} from './services/badge.service';
import {ApprovalService} from './services/approval.service';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatOptionModule,
        MatButtonModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatOptionModule,
        MatButtonModule,
    ],
    providers: [
        AclService,
        BadgeService,
        ApprovalService,
    ],
})
export class SharedModule {
}

import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {ServiceLocator} from '../../../di/ServiceLocator';
import {Location} from '@angular/common';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class FormDataService {
    private entity: ReplaySubject<any>;

    constructor() {
        this.entity = new ReplaySubject(1);
    }

    getEntity(): Observable<any> {
        return this.entity.asObservable();
    }

    update(data): boolean {
        if (data === null) {
            ServiceLocator.injector.get(MatSnackBar).open('The requested entity does not exist or you do not have permission to access it.', undefined, {
                panelClass: ['bg-danger'],
            });
            ServiceLocator.injector.get(Location).back();
            return false;
        }
        this.entity.next(data);
    }

    reset(): void {
        this.entity = new ReplaySubject(1);
    }
}

export const FormDataServiceProvider = {
    provide: FormDataService,
    useFactory: formsServiceFactory
};

export function formsServiceFactory(): FormDataService {
    return new FormDataService();
}

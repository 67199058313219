import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseApi} from '@core/api/BaseApi';


@Injectable()
export class ManufacturerService extends BaseApi {
  protected resourcePath = '/recipes';
}

export const ManufacturerServiceProvider = {
  provide: ManufacturerService,
  useFactory: manufacturerServiceFactory,
  deps: [HttpClient]
};

export function manufacturerServiceFactory(http: HttpClient): ManufacturerService {
  return new ManufacturerService(http);
}

import {OnInit, Directive, Input, ElementRef, AfterViewInit, EventEmitter} from '@angular/core';
import {BaseApi} from '@core/api/BaseApi';
import {EventBus} from '@core/event/EventBus';
import {ServiceLocator} from '@core/di/ServiceLocator';
import {Event} from '@core/event/Event';

@Directive({
    selector: '[inlineAdd]',
    exportAs: 'inlineAdd'
})
export class InlineAddDirective {
    formName: string;
    formTitle: string;
    formService: BaseApi;
    multi = false;
    modal = false;
    private msgBus = ServiceLocator.injector.get(EventBus);
    private button;

    formData = new EventEmitter();

    constructor(
        private element: ElementRef,
    ) {}

    @Input() set inlineAdd(data: {
        formName: string,
        formTitle: string,
        service: BaseApi,
        multi?: boolean,
        modal?: boolean,
    }) {
        if (data === null) {
            this.button && this.element.nativeElement.removeChild(this.button);
            return;
        }
        this.formName = data.formName;
        this.formTitle = data.formTitle;
        this.formService = data.service;
        this.multi = (typeof(data.multi) !== 'undefined') ? data.multi : false;
        this.formData.next({
            formName: this.formName,
        });
        typeof(data.modal) !== 'undefined' && (this.modal = data.modal);
        this.render();
    }

    private showInlineAddDialog(event) {
        event.stopImmediatePropagation();
        event.stopPropagation();
        event.returnValue = false;
        this.msgBus.$emit(new Event('show-inline-add-' + this.formName, {
            formName: this.formName,
            formTitle: this.formTitle,
            service: this.formService,
        }));
        return false;
    }

    private createButton() {
        const button = document.createElement('div');
        const icon = document.createElement('i');
        button.classList.add('btn', 'btn-success');
        icon.classList.add('fa', 'fa-plus');
        button.appendChild(icon);
        button.onclick = this.showInlineAddDialog.bind(this);
        button.style.position = 'absolute';
        button.style.right = '15px';
        button.style.top = (this.multi) ? '0' : '1px';
        if (this.multi) {
            button.style.border = '0';
        }
        button.style.zIndex = '1';
        this.button = button;
        return button;
    }


    render() {
        if (this.modal) {
            return;
        }
        if (!this.multi) {
            // this.element.nativeElement.children[0].style.paddingRight = '48px';
        }
        // console.log(this.createButton());
        this.element.nativeElement.appendChild(this.createButton());
    }

}

import { NgModule } from '@angular/core';
import {SharedModule} from '@shared/shared.module';
import {SubFormErrorsComponent} from './sub-form/errors/errors.component';
import {UserSubFormComponent} from './user/user.component';
import {ImageUploadSingleComponent} from './image-upload-single/image-upload-single.component';
import {ChangePasswordBtnComponent} from './change-password-btn/change-password-btn.component';
import {FileInputComponent} from './file/file.component';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {MaterialSelectComponent} from './material-select/material-select.component';
import {MatSelectModule} from '@angular/material/select';
import {BaseSelectComponent} from './base-select/base-select.component';
import { MaterialSelectComponent as AppMaterialSelect } from '../form/element/material-select/material-select.component';
import {MatInputModule} from '@angular/material/input';
import {PermissionMultiselectComponent} from './permission-multiselect/permission-multiselect.component';
import {MatSliderModule} from '@angular/material/slider';
import {CarouselItemComponent} from './carousel-item/carousel-item.component';
import {DraggableDirective, DroppableDirective, NgxDnDModule} from '@swimlane/ngx-dnd';
import {IngredientMultiselectComponent} from './ingredient-multiselect/ingredient-multiselect.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        SharedModule,
        MatSelectModule,
        MatInputModule,
        MatSliderModule,
        NgxDnDModule,
    ],
    declarations: [
        BaseSelectComponent,
        MaterialSelectComponent,
        SubFormErrorsComponent,
        UserSubFormComponent,
        ImageUploadSingleComponent,
        PermissionMultiselectComponent,
        IngredientMultiselectComponent,
        ChangePasswordBtnComponent,
        FileInputComponent,
        MaterialSelectComponent,
        AppMaterialSelect,
        CarouselItemComponent,
    ],
    exports: [
        BaseSelectComponent,
        MaterialSelectComponent,
        SubFormErrorsComponent,
        UserSubFormComponent,
        ImageUploadSingleComponent,
        PermissionMultiselectComponent,
        IngredientMultiselectComponent,
        ChangePasswordBtnComponent,
        FileInputComponent,
        AppMaterialSelect,
        CarouselItemComponent,
    ],
    providers: [
        DraggableDirective,
        DroppableDirective,
    ]
})
export class FormInputsModule { }

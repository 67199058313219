import { Injectable } from '@angular/core';
import {BaseApi} from '@core/api/BaseApi';
import {HttpClient} from '@angular/common/http';

@Injectable()

export class ApprovalService extends BaseApi {
  protected resourcePath = '/approvals';
}

export const ApprovalServiceProvider = {
  provide: ApprovalService,
  useFactory: approvalsServiceFactory,
  deps: [HttpClient]
};

export function approvalsServiceFactory(http: HttpClient): ApprovalService {
  return new ApprovalService(http);
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ReplaySubject} from 'rxjs';
import {environment} from '../../environments/environment';
import {ServiceLocator} from '../di/ServiceLocator';
import {Router} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import {HttpResponse, HttpHeaders, HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Location} from '@angular/common';
import {map} from 'rxjs/operators';

@Injectable()
export class BaseApi {
    private request: Observable<object>;
    private subject: ReplaySubject<any>;
    protected baseUrl = environment.api.baseUrl;
    protected resourcePath: string;
    protected headers: HttpHeaders = new HttpHeaders();

    constructor(
        protected http: HttpClient,
    ) {
        this.subject = new ReplaySubject(1);
    }

    public reset(): void {
        this.subject = new ReplaySubject(1);
    }

    setHeader(name: string, value: string): void {
        if (!this.headers.has(name) && !!value) {
            this.headers = this.headers.append(name, value);
        }
    }

    setResourcePath(path): void {
        this.resourcePath = path;
    }

    isUnauthorized(err): boolean {
        if (err.status === 401) {
            window.localStorage.removeItem('authorization');
            if (typeof(err?.error?.payload) !== 'undefined') {
                window.localStorage.setItem('login_page_error', JSON.stringify(err.error.payload));
            }
            ServiceLocator.injector.get(Router).navigate(['/login']);
            return true;
            // this.loginDialog.open(LoginModalComponent);
        }
        return false;
    }

    isForbidden(err): boolean {
        if (err.status === 403) {
            ServiceLocator.injector.get(Location).back();
            this.displayError(err);
            return true;
        }
        return false;
    }

    get(path: string | object = this.resourcePath, refresh: boolean = false, options?: { [t: string]: any }): Observable<any> {
        if (typeof(path) === 'object') {
            this.subject.next(path);
            return this.subject.asObservable();
        }
        if (typeof(path) !== 'string') {
            throw new Error('API Resource Path not set.');
        } else if (typeof(this.resourcePath) === 'undefined') {
            this.setResourcePath(path);
        }
        if (this.resourcePath === path) {
            path = '';
        }
        const requestOptions = Object.assign({}, {
            headers: this.headers
        }, options);
        if (refresh || !this.request) {
            this.request = this.http.get(this.baseUrl + this.resourcePath + path, requestOptions).pipe(
                map((res: HttpResponse<object>) => res || {})
            );
            this.request.subscribe(
                res => this.subject.next(res),
                err => {
                    if (!this.isUnauthorized(err) && !this.isForbidden(err)) {
                        this.displayError(err);
                        this.subject.error(err);
                    }
                }
            );
        }
        return this.subject.asObservable();
    }

    download(path: string): Observable<any> {
        return this.get(path, true, {
            responseType: 'blob',
        });
    }

    post(data, onSuccess?, onError?): Observable<any> {
        const subject = new ReplaySubject(1);
        this.http.post(this.baseUrl + this.resourcePath, data, {
            headers: this.headers
        }).subscribe(
            (typeof(onSuccess) !== 'undefined') ? onSuccess : res => subject.next(res),
            err => {
                const errCallback = (typeof(onError) !== 'undefined') ? onError : error => subject.next(err);
                if (!this.isUnauthorized(err) && !this.isForbidden(err)) {
                    this.displayError(err);
                    errCallback(err);
                }
            }
        );
        return subject.asObservable();
    }

    patch(id, data, onSuccess?, onError?): Observable<any> {
        const subject = new ReplaySubject(1);
        this.http.put(this.baseUrl + this.resourcePath + '/' + (id !== null ? id : ''), data, {
            headers: this.headers
        }).subscribe(
            (typeof(onSuccess) !== 'undefined') ? onSuccess : res => subject.next(res),
            err => {
                const errCallback = (typeof(onError) !== 'undefined') ? onError : error => subject.next(err);
                if (!this.isUnauthorized(err) && !this.isForbidden(err)) {
                    this.displayError(err);
                    errCallback(err);
                }
            }
        );
        return subject.asObservable();
    }

    delete(id, onSuccess?, onError?): Observable<any> {
        const subject = new ReplaySubject(1);
        this.http.delete(this.baseUrl + this.resourcePath + '/' + id, {
            headers: this.headers
        }).subscribe(
            (typeof(onSuccess) !== 'undefined') ? onSuccess : res => subject.next(res),
            err => {
                const errCallback = (typeof(onError) !== 'undefined') ? onError : error => subject.next(err);
                if (!this.isUnauthorized(err) && !this.isForbidden(err)) {
                    this.displayError(err);
                    errCallback(err);
                }
            }
        );
        return subject.asObservable();
    }

    restore(id, onSuccess?, onError?): Observable<any> {
        const subject = new ReplaySubject(1);
        this.http.post(this.baseUrl + this.resourcePath + '/' + id, {active: 1}, {
            headers: this.headers
        }).subscribe(
            (typeof(onSuccess) !== 'undefined') ? onSuccess : res => subject.next(res),
            err => {
                const errCallback = (typeof(onError) !== 'undefined') ? onError : error => subject.next(err);
                if (!this.isUnauthorized(err) && !this.isForbidden(err)) {
                    this.displayError(err);
                    errCallback(err);
                }
            }
        );
        return subject.asObservable();
    }

    displayError(err: HttpErrorResponse): void {
        if (err.status === 501) {
            ServiceLocator.injector.get(Router).navigate(['/404']);
            return;
        }
        if (err.status === 0) {
            err.error.message = 'Error';
            err.error.payload = {
                body: 'An internal system error has occurred.  Please try again in a moment.'
            };
        }
        const getBody = (error: HttpErrorResponse) => {
            let body = null;
            if (typeof(error?.error?.payload) !== 'undefined') {
                let payload = null;
                try {
                    payload = JSON.parse(error.error.payload);
                } catch (e) {
                    payload = error.error.payload;
                }
                if (typeof(payload.body) !== 'undefined') {
                    body = payload.body;
                }
            }
            return body || error?.error?.message || error?.error?.Error || null;
        };

        ServiceLocator.injector.get(MatSnackBar).open(getBody(err), undefined, {
            duration: 5000,
            panelClass: ['red-700'],
            horizontalPosition: 'end',
        });
    }

    getResourcePath(): string {
        return this.resourcePath.slice(1);
    }
}

import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {BaseComponent} from '../../Base';
import {ActivatedRoute} from '@angular/router';
import {BaseApi} from '../../../api/BaseApi';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {InlineAddComponent} from '../inline-add/inline-add.component';
import {InlineAddDirective} from '@shared/directives/inline-add/inline-add.directive';

@Component({
    selector: 'base-select',
    template: ``,
})
export class BaseSelectComponent extends BaseComponent {

    @Input() placeholder: string;
    @Input() allowNone: boolean;
    @Input() ctrl: FormControl = new FormControl({});
    @Input() selectedValue: Array<object>;
    @Input() multiple = false;
    @Input() isModal = false;
    @Input() minWidth: number;
    @Output() updated = new EventEmitter();
    @ViewChild(InlineAddDirective, {static: false}) inlineAdd;

    public value: any = {};
    protected service: BaseApi;
    protected route: ActivatedRoute;
    protected dialog: MatDialog;

    items = [];

    selected = [{
        id: '',
        text: '-- Select One --'
    }];

    itemsMap(item): {id: number, text: string} {
        return {
            id: item.id,
            text: item.name
        };
    }

    onSelect(event): void {
        const value = (this.multiple) ? event : event['id'];
        this.updated.emit(value);
    }

    getService(): BaseApi {
        return this.service;
    }

    addCreatedToList(result): void {
        if (result) {
            const item = {
                id: result.id,
                text: result.name
            };
            if (typeof(this.selectedValue) !== 'undefined' && this.multiple === true) {
                const newValue = this.selectedValue.slice(0);
                newValue.push(item);
                this.selectedValue = newValue;
            } else {
                this.selectedValue = [item];
            }
            const items = this.items.slice(0);
            items.unshift(item);
            this.items = items;
            this.updated.emit((this.multiple) ? this.selectedValue : this.selectedValue[0]['id']);
        }
    }

    onRemove(): void {
        this.updated.emit(null);
    }

    showInlineAdd(data): void {
        const config = new MatDialogConfig();
        config.data = {
            form: data.formName,
            title: data.formTitle,
            service: data.service
        };
        if (typeof(this.dialog) !== 'undefined') {
            const dialogRef = this.dialog.open(InlineAddComponent, config);
            this.subscribe(dialogRef.afterClosed(), this.addCreatedToList.bind(this));
        }
    }

    setValue(value?): (response: any) => void {
        return response => {
            this.items = response.data.map(this.itemsMap.bind(this));
            const currentValue = this.items.filter(item => {
                return value && (item.id === value
                    || (typeof(value.indexOf) !== 'undefined' && value.indexOf(item.id) !== -1));
            });
            if (currentValue.length > 0) {
                this.selectedValue = currentValue.map(item => item.id);
                this.updated.emit((this.multiple) ? this.selectedValue : this.selectedValue[0]['id']);
            }
        };
    }

    initialize(queryString: string, init?: Array<any>): void {
        if (typeof(this.inlineAdd) !== 'undefined') {
            this.subscribe(this.inlineAdd.formData, (formData) => {
                this.$on('show-inline-add-' + formData.formName, this.showInlineAdd.bind(this));
            });
        }
        if (typeof(init) !== 'undefined') {
            this.items = init;
        }
        if (typeof(this.service) === 'undefined' && this.items.length === 0) {
            throw new Error('Service not initialized!');
        }
        this.route.params.subscribe(params => {
            if (typeof(params['id']) !== 'undefined') {
                if (this.ctrl.value !== '') {
                    queryString += ((queryString.length > 0) ? '&' : '?') + 'include=' + this.ctrl.value.map(item => item.id);
                    this.subscribe(this.service.get(queryString, false), this.setValue(this.ctrl.value));
                } else {
                    const sub = this.subscribe(this.ctrl.valueChanges, value => {
                        queryString += ((queryString.length > 0) ? '&' : '?') + 'include=' + value.filter(item => !!item).map(item => item.id);
                        this.subscribe(this.service.get(queryString, false), this.setValue(value));
                        this.unsubscribe(sub);
                    });
                }
            } else {
                console.log('3');
                this.subscribe(this.service.get(queryString), this.setValue());
            }
        });
    }

    public refreshValue(value: any): void {
        this.value = value;
    }

}

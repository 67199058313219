import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseSelectComponent} from '../base-select/base-select.component';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'permission-multiselect',
    templateUrl: './permission-multiselect.component.html',
    styleUrls: ['./permission-multiselect.component.scss'],
    providers: [
    ]
})
export class PermissionMultiselectComponent extends BaseSelectComponent implements OnInit {

    @Output() updated = new EventEmitter();
    @Input() placeholder = '';

    public value: Array<number> = [];
    permissions = [];

    constructor(
        protected route: ActivatedRoute
    ) {
        super();
    }

    public refreshValue(value: any): void {
        this.value = value;
    }

    itemsMap(permission): {id: number, text: string} {
        return {
            id: permission.permissionId,
            text: permission.permissionName
        };
    }

    ngOnInit(): void {
        this.initialize('?fields=permission.id,permission.name');
    }

}

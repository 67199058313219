import { Injectable } from '@angular/core';
import {ApprovalService} from './approval.service';


@Injectable()
export class BadgeService {

  private sourcesMap = {
    approval: {
      service: this._approvalService,
      parameters: {
        count: {
          uri: '?q=approval.approved:~,approval.rejected:~&fields=count:approval.id',
          mapper: ({data: [{ approvalIdCount }]}) => ({
            title: approvalIdCount,
            fg: '#333333',
            bg: '#E0E0E0',
          }),
        },
      }
    },
  };

  constructor(
      private readonly _approvalService: ApprovalService,
  ) {}

  async badge(source: string): Promise<{title: string, bg: string | undefined, fg: string | undefined} | undefined> {
    const [serviceName, parameterName] = source.split('.');
    const service = this.sourcesMap[serviceName]?.service;
    if (!service) {
      return undefined;
    }
    const parameter = this.sourcesMap[serviceName].parameters[parameterName];
    return new Promise((resolve) => {
      service.get(parameter.uri).subscribe((res) => {
        const badge = parameter.mapper(res);
        if (+badge.title === 0) {
          resolve(undefined);
        }
        resolve(badge);
      });
    });
  }

}

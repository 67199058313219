import {BaseComponent} from '../../Base';
import {FormGroup} from '@angular/forms';
import {BaseApi} from '../../../api/BaseApi';
import {ServiceLocator} from '../../../di/ServiceLocator';
import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';

@Component({
    selector: 'base-form',
    template: ``,
})
export class BaseFormComponent extends BaseComponent {

    get submitCallback(): () => Observable<any> | void {
        return this.onSubmit.bind(this);
    }

    get cancelCallback(): () => any {
        return this.onCancel.bind(this);
    }

    get cancelButtonText(): String {
        if (typeof(this.form) !== 'undefined' && this.form.pristine) {
            return 'Back';
        }
        return 'Cancel';
    }

    get canSubmit(): boolean {
        return this.saveInProgress === false && typeof(this.form) !== 'undefined' && this.form.valid;
    }

    protected service: BaseApi;
    form: FormGroup;
    forceShowValidation = false;
    entity: object;
    cancelWarned = false;
    title = 'Edit';
    trackingAction = 'Admin';
    isCopy = false;
    loaded = false;
    saveInProgress = false;

    protected snackBar = ServiceLocator.injector.get(MatSnackBar);

    static parseElementErrors(errors): any {
        const errorMap = {
            'presenceof': 'required'
        };
        const errorsObject = {};
        for (const error of errors) {
            errorsObject[(typeof(errorMap[error]) !== 'undefined') ? errorMap[error] : error] = true;
        }
        return errorsObject;
    }

    save(id: number | boolean = false, successCallback?: Function): Observable<any> {
        const onSuccess = res => {
            this.snackBar.open('Successfully ' + ((id !== false) ? 'Saved' : 'Created'), undefined, {
                duration: 5000,
                panelClass: 'green-800',
                horizontalPosition: 'right',
            });

            this.trackAnalytics(((id !== false) ? 'Updated ' : 'Created ') + this.service.getResourcePath(),
                {category: this.trackingAction, label: this.form.value['name']  ? this.form.value['name'] : ''});
            this.saveInProgress = false;
            return (typeof(successCallback) !== 'undefined')
                ? successCallback(res)
                : window.history.go(-1);
        };
        const onError = err => {
            this.forceShowValidation = true;
            this.setErrors(err.error.payload);
            this.saveInProgress = false;
            document.getElementsByTagName('app-root')[0].scrollTop = 0;
        };
        return (id !== false) ? this.service.patch(id, this.form.value,
            onSuccess,
            onError
        ) : this.service.post(this.form.value,
            onSuccess,
            onError
        );
    }

    onSubmit(successCallback?: Function): Observable<any> | void {
        if (this.canSubmit) {
            this.saveInProgress = true;
            this.forceShowValidation = false;
            return (typeof (this.entity) !== 'undefined' && typeof (this.entity['id']) !== 'undefined')
              ? ((typeof (successCallback) !== 'undefined')
                ? this.save(this.entity['id'], successCallback)
                : this.save(this.entity['id']))
              : ((typeof (successCallback) !== 'undefined')
                ? this.save(-1, successCallback)
                : this.save());
        } else {
            this.forceShowValidation = true;
        }
    }

    onCancel(): void {
        if (this.cancelWarned || this.form.pristine) {
            this.cancelWarned = true;
            return window.history.go(-1);
        }
        this.snackBar.open('You have unsaved changes! Click the cancel button again to confirm.', undefined, {
            duration: 2500,
            panelClass: ['bg-warning'],
        });
        this.cancelWarned = true;
    }

    onFormUpdate(event): void {
        this.form = event;
    }

    setErrors(body): void {
        if (typeof(body) !== 'undefined') {
            const elements = Object.keys(this.form.controls);
            for (const element of elements) {
                if (typeof(body[element]) !== 'undefined') {
                    this.form.controls[element].setErrors(BaseFormComponent.parseElementErrors(body[element]));
                }
            }
        }
    }

}

import {BaseComponent} from '../Base';
import {BaseApi} from '../../api/BaseApi';
import {Filters} from '../../filter/filters';
import {Component, Inject, Injectable, TemplateRef, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ServiceLocator} from '../../di/ServiceLocator';
import {FuseConfirmDialogComponent} from '../../../@fuse/components/confirm-dialog/confirm-dialog.component';

interface DialogData {
    text: string;
    onConfirm: () => any;
}

interface ITableColumn {
    name?: string;
    prop?: string;
    sortable?: boolean;
    cellTemplate?: TemplateRef<any>;
    maxWidth?: number;
}

@Component({
    selector: 'confirm-dialog',
    templateUrl: './confirmation-dialog.tpl.html',
})
export class ConfirmationDialogComponent {

    constructor(
      public dialogRef: MatDialogRef<FuseConfirmDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

    onConfirm(): void {
        this.data.onConfirm();
        this.onNoClick();
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}

@Injectable()
export class BaseAdminListComponent extends BaseComponent {

    @ViewChild('baseCellTpl', {static: false}) baseCellTpl: TemplateRef<any>;

    protected service: BaseApi;
    protected dialog: MatDialog = ServiceLocator.injector.get(MatDialog);
    protected snackBar: MatSnackBar = ServiceLocator.injector.get(MatSnackBar);

    loading = true;
    filterVar = '';
    items = [];
    columns = [];
    allowShowInactive = false;
    filterColumn: any = false;
    filterColumns = [];
    activeColumn: string;
    showInactive = false;

    constructor() {
        super();
        this.allowShowInactive = typeof(this.acl) !== 'undefined' && this.acl['super_user'];
        if (!this.allowShowInactive) {
            this.subscribe(this.aclLoad, acl => {
                this.allowShowInactive = acl['super_user'];
            });
        }
    }

    private noPermissionSnackBar(): void {
        this.snackBar.open('You do not have permission to perform this action.', undefined, {
            duration: 5000,
            panelClass: ['bg-danger'],
        });
    }

    getRows(): Array<any> {
        return this.items.filter(item => {
            if (!this.showInactive && typeof(this.activeColumn) !== 'undefined' && !item[this.activeColumn]) {
                return false;
            }
            const filterReducer = (result, prop) => result + ' ' + ((typeof(item[prop]) === 'string') ? item[prop].toLowerCase() : '');
            return Filters.stringContains(
                (this.filterColumn === false)
                    ? this.filterColumns.map(column => {
                        return (typeof(column['prop']) === 'string')
                            ? item[column['prop']]
                            : (typeof(column['prop'].reduce) === 'function')
                                ? column['prop'].reduce(filterReducer, '')
                                : '';
                    })
                    : (typeof(this.filterColumn.reduce) === 'function')
                        ? this.filterColumn.reduce(filterReducer, '')
                        : item[this.filterColumn],
                this.filterVar
            );
        });
    }

    restoreRecord(id, name, index): void {
        const onConfirm = () => {
            this.service.restore(id, () => {
                    // tslint:disable-next-line
                    (typeof(this.activeColumn) !== 'undefined') && (this.items[index][this.activeColumn] = 0);
                    this.trackAnalytics('Restored ' + this.service.getResourcePath(), {
                        category: 'Admin',
                        label: name
                    });
                    this.refresh();
                }, err => {
                    if (err.status === 403) {
                        this.noPermissionSnackBar();
                    }
                }
            );
        };

        this.dialog.open(ConfirmationDialogComponent, {
            width: '250px',
            data: {
                text: 'You are about to restore the item: ' + name,
                onConfirm: onConfirm.bind(this),
            }
        });
    }

    deleteRecord(id, name, index): void {
        const onConfirm = () => {
            this.service.delete(id, () => {
                // tslint:disable-next-line
                (typeof(this.activeColumn) !== 'undefined') && (this.items[index][this.activeColumn] = 0);
                this.trackAnalytics('Deleted ' + this.service.getResourcePath(), {
                    category: 'Admin',
                    label: name
                });
                this.refresh();
            }, err => {
                if (err.status === 403) {
                    this.noPermissionSnackBar();
                }
            });
        };

        this.dialog.open(ConfirmationDialogComponent, {
            width: '250px',
            data: {
                text: `You are about to delete the item: ${name}.  Do you wish to continue?`,
                onConfirm: onConfirm.bind(this),
            }
        });
    }

    protected setColumnWidth(numColumns?: number): number {
        const columns = (typeof (numColumns) !== 'undefined') ? numColumns : 2;
        return (columns * 45) + 30;
    }

    protected getFilterColumns(): Array<{prop: string, text: string}> {
        return this.columns
          .filter((column) => column.filter)
          .map((column) => ({
              prop: column.prop,
              text: column.name,
          }));
    }

    protected addColumn(
        column: ITableColumn,
        index?: number): this {
        const hasProp = (typeof(column.prop) !== 'undefined' && column.prop.length > 0);
        if (!hasProp && typeof(column.cellTemplate) === 'undefined') {
            throw new Error('Column requires a prop or a cell template.');
        }
        if (!hasProp && column.sortable === true) {
            throw new Error('Column cannot be sortable without a defined prop.');
        }
        const definition = {
            prop: column.prop,
            name: (typeof(column.name) === 'undefined') ? '' : column.name,
            sortable: (typeof(column.sortable) === 'undefined')
                ? (typeof(column.prop) !== 'undefined' && column.prop.length > 0) ? true : false
                : column.sortable,
            cellTemplate: (typeof(column.cellTemplate) === 'undefined') ? this.baseCellTpl : column.cellTemplate
        };

        if (typeof(column.maxWidth) !== 'undefined') {
            definition['maxWidth'] = this.setColumnWidth(column.maxWidth);
        }
        setTimeout(() => {
            (typeof(index) !== 'undefined')
                ? this.columns.splice(index, 0, definition)
                : this.columns.push(definition);
        });
        return this;
    }

    protected refreshFunction(showInactive?: boolean): void {
        return;
    }

    get refresh(): () => any {
      const callback = (showInactive?: boolean) => {
        this.loading = true;
        this.showInactive = showInactive;
        this.refreshFunction(showInactive);
      };
      return callback.bind(this);
    }

    get onFiltered(): () => any {
      const callback = ($event: any) => {
        this.filterVar = $event;
      };
      return callback.bind(this);
    }

    get onFilterModeChanged(): () => any {
      const callback = ($event: any) => {
        this.filterColumn = $event;
      };
      return callback.bind(this);
    }
}

import { Injectable } from '@angular/core';
import {BaseApi} from '@core/api/BaseApi';
import {HttpClient} from '@angular/common/http';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import * as http from 'http';

@Injectable()
export class DashboardService extends BaseApi {
  protected resourcePath = '/recipes';

  public recipes = [];

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    return new Promise((resolve, reject) => {
      this.get('').subscribe(({data}) => {
        this.recipes = data;
        resolve(void(0));
      }, reject);
    });
  }
}

export const DashboardServiceProvider = {
  provide: DashboardService,
  useFactory: dashboardServiceFactory,
  deps: [HttpClient]
};

export function dashboardServiceFactory(client: HttpClient): DashboardService {
  return new DashboardService(client);
}

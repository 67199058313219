import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseSelectComponent} from '../base-select/base-select.component';
import {ActivatedRoute} from '@angular/router';
import {IngredientService, IngredientServiceProvider} from '../../../../@shared/services/ingredient.service';

@Component({
    selector: 'ingredient-multiselect',
    templateUrl: './ingredient-multiselect.component.html',
    styleUrls: ['./ingredient-multiselect.component.scss'],
    providers: [
        IngredientServiceProvider,
    ]
})
export class IngredientMultiselectComponent extends BaseSelectComponent implements OnInit {

    @Output() updated = new EventEmitter();
    @Input() placeholder = '';

    public value: Array<number> = [];
    permissions = [];

    constructor(
        protected route: ActivatedRoute,
        protected service: IngredientService,
    ) {
        super();
    }

    public refreshValue(value: any): void {
        this.value = value;
    }

    itemsMap(ingredient): {id: number, text: string} {
        return {
            id: ingredient.ingredientId,
            text: ingredient.ingredientDisplayName
        };
    }

    ngOnInit(): void {
        this.initialize('?fields=ingredient.id,ingredient.display_name');

    }

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseComponent} from '../../Base';
import {FormControl} from '@angular/forms';
import {CarouselService, CarouselServiceProvider} from '../../../../@shared/services/carousel.service';

@Component({
    selector: 'carousel-item-input  ',
    templateUrl: './carousel-item.component.html',
    styleUrls: ['./carousel-item.component.scss'],
    providers: [
        CarouselServiceProvider,
    ]
})
export class CarouselItemComponent extends BaseComponent implements OnInit {

    constructor(
        private readonly service: CarouselService,
    ) {
        super();
    }

    @Output() updated = new EventEmitter();
    @Input() selectedValue: Array<object>;
    @Input() ctrl: FormControl = new FormControl({});
    value = [];
    items = [];

    onDrop(): void {
        console.log(this.items);
    }

    ngOnInit(): void {
        this.subscribe(
            this.service.get('?q=carousel.id:1&fields=carousel_item.id,carousel_item.src,carousel_item.href,' +
                'carousel_item.label,carousel_item.start,carousel_item.end&groupBy=carousel_item.id'),
            ({data}) => {
                this.items = data.map(({carouselItemId: id, carouselItemLabel: label}) => ({
                    id,
                    label,
                }));
            }
        );
    }

}

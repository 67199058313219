<div class="form-field-container" [ngSwitch]="element['type']" fxLayout="row">
    <mat-form-field
        fxFlex="100%"
        appearance="outline"
        *ngSwitchCase="'text'"
    >
        <mat-label>{{ element['label'] }}</mat-label>
        <input
            fxFlex="100%"
            type="text"
            matInput
            [name]="element['name']"
            [formControl]="ctrl"
            [attr.disabled]="isDisabled()"
            ngDefaultControl
            class="form-control"
        />
    </mat-form-field>

    <mat-form-field
        fxFlex="100%"
        appearance="outline"
        *ngSwitchCase="'numeric'"
    >
        <input
            type="number"
            matInput
            [name]="element['name']"
            [formControl]="ctrl"
            [attr.disabled]="isDisabled()"
            ngDefaultControl
            [attr.min]="element['options']['min'] || null"
            [attr.max]="element['options']['max'] || null"
            class="form-control"
            [placeholder]="element['label']"
        />
    </mat-form-field>

    <mat-form-field
        fxFlex="100%"
        appearance="outline"
        *ngSwitchCase="'email'"
    >
        <input
            type="email"
            matInput
            [name]="element['name']"
            [formControl]="ctrl"
            [attr.disabled]="element['options']['disabled'] ? 'disabled' : null"
            class="form-control"
            (keyup)="onTextChange($event)"
            (change)="onTextChange($event)"
            [placeholder]="element['label']"
        />
    </mat-form-field>


    <mat-form-field
        fxFlex="100%"
        appearance="outline"
        *ngSwitchCase="'password'"
    >
        <input
            matInput
            type="password"
            [name]="element['name']"
            [formControl]="ctrl"
            [attr.disabled]="element['options']['disabled'] ? 'disabled' : null"
            (keyup)="onTextChange($event)"
            (change)="onTextChange($event)"
            [placeholder]="element['label']"
        >
    </mat-form-field>

    <app-material-select
        style="width: 100% !important"
        *ngSwitchCase="'select'"
        [items]="element['selectOptions']"
        [ctrl]="ctrl"
        [placeholder]="element['label']"
        (updated)="onChange($event)"
    ></app-material-select>

    <mat-button-toggle-group
        *ngSwitchCase="'radiobuttongroup'"
        [id]="element['name']"
    >
        <mat-button-toggle
            fxFlex="100%"
            [id]="button.options.id"
            *ngFor="let button of element['elements']"
            [value]="button.options.value"
            [checked]="value == button.options.value || element['options'].default == button.options.value"
            [attr.disabled]="element['options']['disabled'] ? 'disabled' : null"
        >
            <mat-icon>{{ button.label }}</mat-icon>
        </mat-button-toggle>
    </mat-button-toggle-group>

    <mat-slide-toggle
        fxFlex="100%"
        *ngSwitchCase="'check'"
        [id]="element['name']"
        [name]="element['name']"
        [formControl]="ctrl"
        [color]="element['options']['color'] || 'primary'"
        [checked]="value == element['options']['value'] || element['options']['default'] == element['options']['value']"
        [disabled]="element['options']['disabled'] ? 'disabled' : null"
    >{{ element['label'] }}</mat-slide-toggle>

    <mat-form-field
        fxFlex="100%"
        appearance="outline"
        *ngSwitchCase="'textarea'"
    >
        <mat-label>{{ element['label'] }}</mat-label>
        <textarea
            matInput
            [name]="element['name']"
            [formControl]="ctrl"
            [attr.disabled]="ctrl['disabledStyle']"
            ngDefaultControl
            class="form-control"
            rows="10"
        ></textarea>
    </mat-form-field>

    <fieldset class="b0" *ngSwitchCase="'divider'">
        <legend>{{ element['options']['title'] }}</legend>
    </fieldset>

    <component-form-element
        fxFlex="100%"
        *ngSwitchCase="'componentelement'"
        [element]="element"
        [ctrl]="ctrl"
        [isModal]="isModal"
        (updated)="onChange($event)"
    ></component-form-element>

    <component-form-element
        *ngSwitchCase="'subform'"
        [element]="element"
        (updated)="onChange($event)"
        (validation)="validation.next($event)"
        [forceShowValidation]="forceShowValidation"
    ></component-form-element>
</div>
<div fxLayout="row">
    <form-control-errors
        fxFlex="100%"
        class="red-500-fg"
        [ctrl]="ctrl"
        [name]="element['name']"
        [validationMessages]="validationMessages"
        [forceShowValidation]="forceShowValidation"
        [isModal]="isModal"
    ></form-control-errors>
</div>

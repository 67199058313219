import {AclService} from '@shared/services/acl.service';
import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core';
import {AclUtils} from './AclUtils';

@Directive({
    selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnInit {

    private acl;

    private requiredPermissions;

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private aclService: AclService
    ) {
        // renderer.setAttribute(elementRef.nativeElement, 'disabled', 'disabled');
        // this.requiredPermissions = elementRef.nativeElement.getAttribute('hasPermission');
        this.acl = aclService.get('acl');
    }

    ngOnInit() {
        // this.acl.subscribe(acl => {
        //     // const hasPermission = AclUtils.hasPermission(this.requiredPermissions, acl);
        //     // if (hasPermission) {
        //         this.renderer.removeAttribute(this.elementRef.nativeElement, 'disabled');
        //     // }
        // });
    }

}

import {NgModule} from '@angular/core';
import {DatatableSimpleComponent} from './datatable-simple.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../../../@shared/shared.module';
import {ConfirmationDialogComponent} from './BaseAdminList';
import {FuseConfirmDialogModule} from '../../../@fuse/components';
import {FuseConfirmDialogComponent} from '../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {DatatableComponent} from './datatable.component';
import {MatCardModule} from '@angular/material/card';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {FlexLayoutModule} from '@angular/flex-layout';


@NgModule({
    imports: [
        SharedModule,
        NgxDatatableModule,
        FormsModule,
        FuseConfirmDialogModule,
        MatCardModule,
        MatIconModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        FlexLayoutModule,
    ],
    declarations: [
        DatatableComponent,
        DatatableSimpleComponent,
        ConfirmationDialogComponent,
    ],
    entryComponents: [
        ConfirmationDialogComponent,
        FuseConfirmDialogComponent,
    ],
    exports: [
        DatatableComponent,
        DatatableSimpleComponent,
        NgxDatatableModule,
        MatFormFieldModule,
        MatSelectModule,
        MatCardModule,
        MatIconModule,
        MatSlideToggleModule,
        MatInputModule,
        FlexLayoutModule,
    ],
})
export class DatatableModule {}

<mat-card>
    <mat-card-header class="pr-16">
        <mat-card-title *ngIf="title">{{ title }}</mat-card-title>
        <div fxFlex></div>
        <div fxLayoutGap="8px">
            <button mat-raised-button (click)="back()">
                Back
            </button>
            <button
                *ngIf="addButtonText && addButtonText.length > 0"
                mat-raised-button
                class="green-700-bg green-700"
                (click)="add()"
            >
                {{ addButtonText }}
            </button>
        </div>
    </mat-card-header>
    <mat-card-header>
        <mat-card-title *ngIf="filterColumns && filterColumns.length > 0">
            <div fxLayout.lg="50%" fxLayoutGap="6px">
                <mat-form-field>
                    <mat-select
                        [(value)]="selected"
                        (valueChange)="filterModeChanged.next($event.prop); filterColumnName = $event.text;"
                        placeholder="Column"
                    >
                        <mat-option [value]="defaultField">
                            All Columns
                        </mat-option>
                        <mat-option
                            *ngFor="let column of filterColumns"
                            [value]="column"
                        >
                            {{column['text']}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field floatLabel="always">
                    <mat-label>Filter Results</mat-label>
                    <input
                        matInput
                        type="text"
                        [(ngModel)]="filter"
                        (ngModelChange)="filtered.next($event);onFilter($event);"
                    />
                </mat-form-field>
            </div>
        </mat-card-title>
        <div fxFlex></div>
        <div fxLayout="row" fxLayoutAlign="end center">
            <mat-slide-toggle
                *ngIf="allowShowInactive"
                class="mr-16"
                [color]="'primary'"
                [checked]="showInactive"
                (change)="showInactive = !showInactive;refresh.next(showInactive)"
            >Show Inactive</mat-slide-toggle>
        </div>
    </mat-card-header>
    <mat-card-content class="p-16">
        <ngx-datatable
            #table
            class="material striped big-loading-text"
            *ngIf="loading === false"
            [rows]="rows"
            [columns]="columns"
            [columnMode]="'force'"
            [limit]="paginationCount || 20"
            [headerHeight]="'auto'"
            [rowHeight]="'auto'"
            [footerHeight]="35"
            (sort)="onColumnSort($event)"
        >
        </ngx-datatable>
        <div *ngIf="loading === true" fxLayout="row" fxLayoutAlign="center center">
            <div class="sk-flow">
                <div class="sk-flow-dot"></div>
                <div class="sk-flow-dot"></div>
                <div class="sk-flow-dot"></div>
            </div>
        </div>
        <div *ngIf="loading === null" class="text-center">
            <h4>An error occurred while loading {{title}}.</h4>
        </div>
    </mat-card-content>
</mat-card>

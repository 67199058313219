import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FormUtils} from '../../form/form/FormUtils';
import {BaseComponent} from '../../Base';

@Component({
    selector: 'sub-form-component',
    templateUrl: './sub-form.component.html',
    styleUrls: ['./sub-form.component.scss']
})
export class SubFormComponent extends BaseComponent implements OnInit {

    @Output() updated = new EventEmitter();
    @Output() validation = new EventEmitter();
    @Input() placeholder = '';
    @Input() form = [];
    @Input() forceShowValidation = false;

    ngForms: FormGroup[] = [];
    validationMessages;

    generateForm(entity?): void {
        const form = FormUtils.generateForm(this.form);
        const ngForm = form.ngForm;
        if (typeof(entity) !== 'undefined') {
            for (const field in entity) {
                if (typeof(ngForm.controls[field]) !== 'undefined') {
                    ngForm.controls[field].patchValue(entity[field]);
                }
            }
        }
        this.ngForms.push(ngForm);
        this.validation.next(false);
        const index = this.ngForms.length - 1;
        this.ngForms[index].valueChanges.subscribe(() => {
            this.updated.next(this.getValuesArray());
            this.validation.next(this.ngForms[index].valid);
        });
        this.updated.next(this.getValuesArray());
        this.validation.next(this.ngForms[index].valid);

        if (!this.validationMessages) {
            this.validationMessages = form.validationMessages;
        }
    }

    getValuesArray(): any[] {
        const values = [];
        for (const form of this.ngForms) {
            values.push(form.value);
        }
        return values;
    }

    removeForm(i): void {
        let valid = true;
        this.ngForms.splice(i, 1);
        for (const form of this.ngForms) {
            if (!form.valid) {
                valid = false;
            }
        }
        this.updated.next(this.getValuesArray());
        this.validation.next(valid);
    }

    ngOnInit(): void {
        this.validation.next(true);
    }
}

import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-material-select',
    templateUrl: './material-select.component.html',
    styleUrls: ['./material-select.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MaterialSelectComponent {

    @Output() updated = new EventEmitter();
    @Input() ctrl: FormControl;
    @Input() placeholder;
    @Input() disabled = false;
    @Input() items = [];
    @Input() multiple = false;

}

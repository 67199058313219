import {FormService} from '@shared/services/form.service';
import {HttpClient} from '@angular/common/http';

export const FormServiceProvider = {
    provide: FormService,
    useFactory: formsServiceFactory,
    deps: [HttpClient]
};

export function formsServiceFactory(http: HttpClient) {
    return new FormService(http);
}

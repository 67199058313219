import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {BaseComponent} from '../../Base';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'form-element',
    templateUrl: './element.component.html',
    styleUrls: ['./element.component.scss'],
})
export class ElementComponent extends BaseComponent implements OnInit {

    @Input() element: object;
    @Input() value: string | number | boolean;
    @Input() ctrl: FormControl;
    @Input() validationMessages = {};
    @Input() forceShowValidation = false;
    @Input() isModal = false;
    @Input() disabled = false;
    @Output() updated = new EventEmitter();
    @Output() validation = new EventEmitter();

    constructor(
    ) {
        super();
    }

    onChange($event): void {
        this.value = $event;
        this.updated.emit(this.value);
    }

    onTextChange($event): void {
        this.value = $event.target.value;
        this.updated.emit(this.value);
    }

    isRequired(element): boolean {
        if (typeof(element['validators']) === 'undefined' || element['validators'].length === 0) {
            return false;
        }
        return element['validators'].filter(validator => validator['type'] === 'presenceof').length > 0;
    }

    onRadioButtonGroupChange($event): void {
        this.updated.emit($event.target.value);
    }

    getPlaceholder(element): string {
        const placeholder = element['options']['placeholder'];
        return placeholder ? placeholder : '';
    }

    isDisabled(): boolean {
        return this.element['options']['disabled'] ||
        ((this.ctrl['disabledStyle'] !== 'undefined') && this.ctrl['disabledStyle'] === true);
    }

    ngOnInit(): void {
        if (typeof(this.element['options']['default']) !== 'undefined' && typeof(this.value) === 'undefined') {
            this.value = this.element['options']['default'];
        }
    }

}

import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import * as jwt from 'jsrsasign';
import {JwtToken} from '../interfaces/JwtToken';
import {UserAttributes} from '../interfaces/UserAttributes';
import {EventBus, Event} from '@core/event';
import {ServiceLocator} from '../../@core/di/ServiceLocator';
import {Router} from '@angular/router';

@Injectable()
export class ProfileService {
    private token: JwtToken;
    private readonly expWatcher: number;
    private readonly tokenWatcher: number;
    private data = new BehaviorSubject<UserAttributes>({
        active: undefined,
        id: undefined,
        practice: undefined,
        user: undefined,
        email: undefined,
        first_name: undefined,
        last_name: undefined,
    });

    public profile: Observable<UserAttributes> = this.data.asObservable();

    constructor(
      private eventBus: EventBus,
    ) {
        this.tokenWatcher = window.setInterval(this.checkTokenExists.bind(this), 500);
        this.expWatcher = window.setInterval(this.checkTokenExpiration.bind(this), 5000);
    }

    public clearWatcher(): void {
        window.clearInterval(this.expWatcher);
    }

    private checkTokenExists(): void {
        const authorization = window.localStorage.getItem('authorization');
        if (typeof(authorization) === 'string') {
            try {
                const authArray = authorization.split('.');
                this.token = jwt.KJUR.jws.JWS.readSafeJSONString(jwt.b64utoutf8(authArray[1]));
                this.data.next(this.token.data);
                window.clearInterval(this.tokenWatcher);
            } catch (e) {
                window.localStorage.clear();
                ServiceLocator.injector.get(Router).navigate(['/login']);
            }
        }
    }

    private checkTokenExpiration(): void {
        if (!this.token) {
            return;
        }
        if ((this.token.eat - 600) < (Date.now() / 1000)) {
            this.eventBus.$emit(new Event('session-warn', {expiresAt: this.token.eat}));
        }
        if (this.token.eat < (Date.now() / 1000)) {
            window.localStorage.removeItem('authorization');
            window.clearInterval(this.expWatcher);
            this.eventBus.$emit(new Event('session-expire'));
        }
    }

    getDefaultPhoto(): string {
        return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAK60lEQVR4Xu2d52sVSxTAJ/YSscSuxG7' +
            'U2EvU2FFQUaz4wRL9dwRR9EvgKrGCYo0FFXuJUVG/JBYUK/bee338BvYRE5N7k53Znd2ZA/J45N4tc3535rQ5k5ZIJP4IJ9aOQJoDwFr' +
            'dyxd3ANitfweA5fp3ADgAnBFoNQPOBrBa/c4ItFz9DgAHgIsD2M2AswHs1r9zAy3XvwPAAeDiAFYz4GwAq9Xv3EDL1e8AcAC4OIDdDDg' +
            'bwG79OzfQcv07ABwALg5gNQPOBrBa/c4NtFz9MQegVq1aIiMjQ7Rt21Y0a9ZMNGnSRDRs2FA0aNBA8Ley8vv3b/H161f57/379+Lt27f' +
            'i2bNn4tWrV+LXr1+xBSVWS0BaWppUcMuWLUVmZqbo0qWL/H8/AhB3794V9+/fFy9evBBfvnwRf/7EZy9NLABA8fy6e/bsKbKyskTjxo3' +
            '96LzS76L869evi5s3b4p3797FAoTIA8DU3r9/f9GpUyc5tQODTuHX/+3bNzkjlJSUiDdv3ui8nfZrRxaAunXrin79+onevXuLRo0aaVd' +
            '8eU0AgjcjlJaWiu/fv2tXlo4bRA4AfuFt2rQRo0ePFi1atNAxJtW+JrNAcXGxePLkSeSWhUgBgPKzs7NFTk6OqFOnTrUVpfMLP3/+FJc' +
            'uXRKXL1+OFASRAaB+/foiNzdXWvamKd8DC3fx3r17cjbAe4iCRAIAXLkJEyaI9u3bV/DfTRtkbIPHjx+L48ePSxvBdDEeAJQ/efJk0bp' +
            '1a9PH8q/ne/nypThw4IDxM4HRANSrV09MmjRJdOzYMVLK9x720aNH4vDhw+LHjx/GPr+xABCqHTt2rOjevbvx035l2iW8fOvWLXHq1Cl' +
            'jDUMjAcDa79OnjxgxYoSoXbu2sb+eVB4Mw7CoqEhGEMvnH1L5vu7PGAkAyZtp06ZFXvme8nARd+zYIV6/fm2cB2McALh4M2fOlFm8OAm' +
            'JpA0bNsg8BTOB7pB1qmNnHACDBw8WQ4YMMWaAUh3IZJ/DPSQ+cPr0aZmaNiWWYRQATZs2lVN/enp6svGM5N8/fPggNm3aJBNIzZs3NwI' +
            'CYwBgSsTqJ6VryvSomjJmgStXrog9e/bIdzQBAmMAYFpk7SfkG2chJlBQUCCrjVgGgABPJyzojQCAlx80aFAs1/7yMDMLnDlzRpw8eVI' +
            'QJyCt7UEQBvhGAEC4d86cOdoqecIY2KruiS2wZs0awX8RIAjLMDQCAKp5iPfbJMQFrl69+v8rhwWBEQBQ3EHkzyahiqiwsPCvVw7DJjA' +
            'CgLy8PN/Vu1GD5/Pnz2LZsmUVHjtomyB0ACjhnj17dmhWcFjgYAyuXbtWPHz4sMIjeDNBEMGi0AGgopekj42CJ0Cm8F8SFAShAzBu3Dh' +
            'Zy2+jYARiDFYmQEDhq87cQegAEPrt0KGDjfqXewvWrVtX5bvrtglCBwD/HzvARiFDmJ+fn/TVdbqIoQOwYMGC2CZ/kmmWQNCKFSuSfUx' +
            'rsCh0ABYtWiR39tgolbmCQdoEDoAQyasuADyqZxiqKpVzAEQMAA8CValkB0AEAVAJQegALFy40JosYHnWPn78KJYvX15jBFUEi0IHYO7' +
            'cubErAE1Vo6m6gVVdz2+wKHQAbA4EPXjwQOYD/IqfOEHoALhQcOWh4OqAUVMIQgfA5mQQiSASQqqkJjZB6AC0atVKzJo1y8p0MHkAlgG' +
            'VUl2bIHQAePnFixfLBk82Cb0DKAjR0XKuOjOBEQCwH6BXr1426V+2ktm1a5e2d07VJjACABuLQnfu3Ck3ieiUVGYCIwAgGUQ8wG9XT52' +
            'DqfLanz59EqtXr5YtaXWLV09QWVGJEQCwMYQNoWwOCWuHjG5FeNdnzT979qzsIcTGkCCkquXACAAYBDZG4A3QFibOwtYwrH96CgYplUF' +
            'gDAC2bA6lDnD37t2hdCD/FwTGAODNAoSGdTV7DvIX9697kfzZuHGj7DoelpS3CYwCgEEZOnRoLG0B1v7z58/LrmFhS1nvwDgAsAGYBYg' +
            'QxkmePn0qW8SY0kHUg8A4AFA6TaKmTp0qd83GQTD8tm7dKm7fvm3U6wCBkQB4TaGHDx8e+U5htIk7ceKEOHfuXGBuX3UoMxIAXoCixzF' +
            'jxogePXpENjbAun/t2jXZEsbUbqHGAgAEtIuhVWxUdw6x82fbtm2CyJ+pYjQADBpZwokTJ8pO4VGJEvLLR/ns+8P1M1mMB4DBI0fATIB' +
            'xaDoEKJ8t39u3b/+/BYwDQMEI4B7SSaRr165G9tzlFYnt0xN4//79gk0fUZBIzADeQOK2cEjUsGHDjGiyWFbBGHmUd128eNFYg+9fQEY' +
            'KAF6AJaBdu3Zi5MiRcu+8CUsCod1Dhw6JO3fuaKnw0TmTRAoAlI1RiEFIUylTbAIMPg6M4oRRLH4dZV66IIgMALiElI317dvX2GQRFv+' +
            'FCxckDFE4L0jOqIlEwuiDcFE8SufkEI6HNfHQhbK/TgxBDp6m3IvCD04ZNVmMBQBFd+vWTQwYMEAWi5iu+PJKBgROHgcCYODQCBPFOAC' +
            '8LtqkhSkWNcHI86M47AHODaIEjAbRptkHRgGAsnHzSALFJRPowcPZwiSFsBGCqgVMBVxjAKAKaNSoUfKIuCAaJKYyOKo/wzKAp0CgiOP' +
            'nTRAjAOAwaDJ/dL2I+pSfTKksAd6hkhwzG7aECgDK5ixgdgjHbcpPplgih/v27ZMdw8NcEkIDAOXj1xPRi+uUnwwCloQjR47IuEFYEIQ' +
            'CAC4dvj0nhMV9H0AyCDAOOUEEd5HqoaAlFABQfhzKvVQpi5kADwEIgpbAAcDNw+BzUnEEDh48KLOJQcYKAgUAg2/8+PHWGXypwo5huHf' +
            'vXmkYBgVBYABQ58+5QFT3xN3VS1Xh5T+H0kkiUUJOhjEICQQAkjjU+XMyqFN+1WoFAg6Z3rJli8wl6BbtAFDeTVFn586ddb9LrK5/48Y' +
            'NQRMJ3eXk2gGwuQuYXyLxDDhsWqdoBYA07vTp061tB+9XcVQXsZv4+fPnfi9V6fe1AoDRl5mZ6db9GqoPe4A6w82bN2uLFGoDgGIOYvy' +
            '2hnlrqPMKXyNIRPawpKREi2uoBQBSu5wEnp6ermocrL4OzaRoK0OpmWrRAgCh3tzcXNXPavX1jh07JoqLi5XPAsoBINBDyzdbzwHSRSk' +
            'Vx7SW804cV3Uf5QAMHDhQ5OTkqHo+d50yI1BUVCRrC1WKUgAo6uAcYNw/J+pHgAhhIpEQpJBViVIAsrOzZYFH1Eq4VQ2m7utQNHL06FH' +
            'ZbUSVKAXA5tM/VCkk2XVoL79+/XplcQFlADDtz5gxw7q278kUpvrvdBmj2xhdx1SIEgDI8FHexT+X7VOhlsqvQXQQdxBjUEXNgBIAqOv' +
            'D+CPd60T/CGAMFhQUKGlCoQSAjIwMwSng7tevX/ncgV8+p43RisavKAEgKytLxv2dBDcC1A+yzcyv+AYAlw/l08/PSXAjQP9BjpzxW0r' +
            'uGwCCP/Pnz3fWf3C6l3eidnDVqlW++w/4BoD9fPPmzQv49d3tGAFyA34PnvANAG3b6OHnJPgRKCwsFKWlpb5u7BsAfH+aOTgJfgQICfs' +
            '9f8A3ADaf/Ru8yv++I4YgHUn9iG8ApkyZIuv+nAQ/AvQXICzsR3wDQPEHgSAnwY8ADSrz8/N93dg3ALiA7PxxEvwI0GZm5cqVvm7sG4A' +
            'lS5bIvv5Ogh8BehAuXbrU140dAL6GL9wvhw4A+/7y8vKs7/IRFgYqAPgP9H0w+uRkjCIAAAAASUVORK5CYII=';
    }
}

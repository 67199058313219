import { NgModule } from '@angular/core';
import {SharedModule} from '@shared/shared.module';
import {FormInputsModule} from '../../input/form-inputs.module';
import {SubFormComponent} from '../../input/sub-form/sub-form.component';
import {ComponentElementComponent} from './component/component-element.component';

@NgModule({
    imports: [
        SharedModule,
        FormInputsModule,
    ],
    declarations: [
        SubFormComponent,
        ComponentElementComponent,
    ],
    exports: [
        FormInputsModule,
        ComponentElementComponent,
    ],
})
export class ComponentElementModule { }

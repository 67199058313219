import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import {ReplaySubject} from 'rxjs';
import {Observable} from 'rxjs';
import * as jwt from 'jsrsasign';
import {AuthService} from '@shared/services/auth.service';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class IsAuthenticated implements CanActivate, CanActivateChild, CanLoad {

    isAuthenticated: ReplaySubject<boolean>;

    constructor(
        private authService: AuthService,
        private router: Router,
    ) {}

    private static verifyToken(authorization: string): any {
        const publicKey = jwt.KEYUTIL.getKey(environment.publicKey);
        const result = jwt.KJUR.jws.JWS.verify(authorization, publicKey, {alg: ['HS256']});
        return result;
    }

    private static tokenIsNotExpired(authorization: string): boolean {
        const authArray = authorization.split('.');
        const token = jwt.KJUR.jws.JWS.readSafeJSONString(jwt.b64utoutf8(authArray[1]));
        return token.eat > (Date.now() / 1000);
    }

    private authFailed(): Observable<boolean> {
        window.localStorage.removeItem('authorization');
        this.router.navigate(['/login']);
        this.isAuthenticated.next(false);
        return this.isAuthenticated.asObservable();
    }

    canActivate(): Observable<boolean> {
        this.isAuthenticated = new ReplaySubject(1);
        const authorization = window.localStorage.getItem('authorization');
        if (!authorization) {
            return this.authFailed();
        }
        let valid: any;
        try {
            valid = IsAuthenticated.verifyToken(authorization) && IsAuthenticated.tokenIsNotExpired(authorization);
        } catch (e) {
            return this.authFailed();
        }
        if (!valid) {
            return this.authFailed();
        }
        this.isAuthenticated.next(valid);
        return this.isAuthenticated.asObservable();
    }

    // logout() {
    //     window.localStorage.clear();
    //     window.localStorage.setItem('returnPath', this.router.routerState.snapshot.url);
    //     this.router.navigateByUrl('/login');
    // }

    canLoad(): Observable<boolean> {
        return this.canActivate();
    }

    canActivateChild(): Observable<boolean> {
        return this.canActivate();
    }

}

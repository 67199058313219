<div class="page-layout simple fullwidth">
    <div class="content" fxFlex="row">
        <div fxFlex.gt-md="30%" fxFlex.md="75%" fxFlex.sm="100%">
            <mat-card>
                <mat-card-header>
                    <mat-card-title style="margin-left: -16px;">{{ title }}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <ng-content select="app-form"></ng-content>
                    <hr style="margin: 24px 0;" />
                </mat-card-content>
                <mat-card-actions>
                    <div dir="rtl" fxLayoutGap="6px" class="button-row">
                        <button
                            mat-raised-button
                            (click)="onCancel()"
                        >
                            {{ cancelText }}
                        </button>
                        <button
                            mat-raised-button
                            color="primary"
                            [disabled]="!canSubmit"
                            (click)="onSubmit()"
                        >
                            {{ submitText }}
                        </button>
                    </div>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
</div>
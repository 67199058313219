<mat-form-field>
  <mat-label>{{ placeholder || 'Select an Option' }}</mat-label>
  <mat-select
    [(ngModel)]="selectedValue"
    (ngModelChange)="updated.emit(selectedValue)"
    [style.minWidth]="minWidth && minWidth + 'px'"
  >
    <mat-option
      *ngFor="let item of items"
      [value]="item.id"
    >{{ item.text }}</mat-option>
  </mat-select>
</mat-form-field>
import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-form-template',
    templateUrl: './template.component.html',
    styleUrls: ['./template.component.scss'],
})
export class TemplateComponent {
    @Input() title = ' ';
    @Input() submitText = 'Submit';
    @Input() canSubmit: boolean;
    @Input() onSubmit: Function;
    @Input() cancelText: string;
    @Input() onCancel: Function;
}

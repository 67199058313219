import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { ChartsModule } from 'ng2-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';

import { DashboardComponent } from './dashboard.component';
import {DashboardService} from '@shared/services/dashboard.service';
import {FuseSidebarModule} from '../../../@fuse/components';
import {IsAuthenticated} from '../../../@core/acl/IsAuthenticated';

const routes: Routes = [
    {
        path     : '',
        pathMatch: 'full',
        canActivate: [IsAuthenticated],
        component: DashboardComponent,
        resolve  : {
            data: DashboardService,
        },
    },
];

@NgModule({
    declarations: [
        DashboardComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatSelectModule,
        MatTabsModule,
        ChartsModule,
        NgxChartsModule,

        FuseSharedModule,
        FuseWidgetModule,
        FuseSidebarModule,
    ],
    providers   : [
        DashboardService,
    ]
})
export class DashboardModule
{
}


<app-datatable-template
    style="width: 100%"
    title="Ingredients"
    addButtonText="Add Ingredient"
    [loading]="loading"
    [rows]="getRows()"
    [columns]="columns"
    [filterColumns]="filterColumns"
    [allowShowInactive]="true"
    [refresh]="refresh"
    [onFiltered]="onFiltered"
    [onFilterModeChanged]="onFilterModeChanged"
    activeProperty="active"
    idProperty="id"
    nameProperty="name"
    (delete)="deleteRecord($event.id, $event.name, $event.index)"
    (restore)="restoreRecord($event.id, $event.name, $event.index)"
></app-datatable-template>

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BaseSelectComponent} from '../base-select/base-select.component';

@Component({
    selector: 'material-select',
    templateUrl: './material-select.component.html',
    styleUrls: ['./material-select.component.scss'],
})
export class MaterialSelectComponent extends BaseSelectComponent {

    @Output() updated = new EventEmitter();
    @Input() placeholder = '';
    @Input() type: number; // 1 is behavior question, 2 is outcome question

    public value: any = {};
    permissions = [];

    constructor() {
        super();
    }

    public refreshValue(value: any): void {
        this.value = value;
    }

}

import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {BaseComponent} from '../../../Base';
import {Form, FormControl} from '@angular/forms';

@Component({
    selector: 'component-form-element',
    templateUrl: './component-element.component.html',
    styleUrls: ['./component-element.component.scss']
})
export class ComponentElementComponent extends BaseComponent implements AfterViewInit {

    @Input() element: object;
    @Input() value: string | number;
    @Input() ctrl: FormControl;
    @Input() forceShowValidation = false;
    @Input() isModal = false;
    @Output() updated = new EventEmitter();
    @Output() validation = new EventEmitter();

    ngAfterViewInit(): void {
        if (typeof(this.element['options']['default']) !== 'undefined' && typeof(this.value) === 'undefined') {
            this.value = this.element['options']['default'];
        }
    }

}

import {Component, OnInit} from '@angular/core';
import {SubFormComponent} from '../sub-form/sub-form.component';
import {FormDataService} from '../../form/form/FormDataService';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'user-sub-form',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserSubFormComponent extends SubFormComponent implements OnInit {

    isUser = false;

    constructor(
        private formDataService: FormDataService,
        private route: ActivatedRoute
    ) {
        super();
    }

    ngOnInit() {
        this.subscribe(this.formDataService.getEntity(), data => {
            if (typeof(data.user) !== 'undefined') {
                this.generateForm(data.user);
            }
        });

    }

}
